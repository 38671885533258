import { ReactComponent as TextIcon } from 'assets/icon-pen-tip.svg';
import { ReactComponent as RatingIcon } from 'assets/icon-star.svg';
import { ReactComponent as YesNoIcon } from 'assets/icon-check-with-circle.svg';
import { ReactComponent as MultipleChoiceIcon } from 'assets/icon-multiple-choice.svg';
import { ReactComponent as AttachmentIcon } from 'assets/icon-paperclip-black.svg';
import { ReactComponent as VideoIcon } from 'assets/icon-video.svg';
import { ReactComponent as NimbleLogoBlack } from 'assets/nimble-logo-black.svg';

import {
  SchoolPreferencesQuestionIcon,
  CumulativeScoreIcon,
  DirectionTextIcon,
  RubricIcon,
  CheckmarkBlackBox,
} from 'ui-kit/icons';

import { questionType, scorecardQType } from 'utils/enums';
import { QuestionType, ScorecardQuestionType } from 'utils/constants';

const iconMap = {
  [questionType.yes_no]: YesNoIcon,
  [questionType.open_response]: TextIcon,
  [questionType.nimble]: NimbleLogoBlack,
  [questionType.multiple_choice]: MultipleChoiceIcon,
  [questionType.attachment]: AttachmentIcon,
  [questionType.direction_text]: DirectionTextIcon,
  [questionType.videoLink]: VideoIcon,
  [questionType.schoolPreferences]: SchoolPreferencesQuestionIcon,
  [questionType.statementCheckbox]: CheckmarkBlackBox,
  [scorecardQType.rating]: RatingIcon,
  [scorecardQType.yes_no]: YesNoIcon,
  [scorecardQType.text]: TextIcon,
  [scorecardQType.nimble]: NimbleLogoBlack,
  [scorecardQType.multiple_choice]: MultipleChoiceIcon,
  [scorecardQType.direction_text]: DirectionTextIcon,
  [scorecardQType.rubric]: RubricIcon,
  [scorecardQType.final_recommendation]: YesNoIcon,
  [scorecardQType.cumulative_score]: CumulativeScoreIcon,
  [scorecardQType.attachment]: AttachmentIcon,
};

type QuestionBoxProps = {
  handleClick: (type: QuestionType | ScorecardQuestionType | null) => void;
  label: string;
  type: QuestionType | ScorecardQuestionType;
};

const QuestionBox: React.FC<QuestionBoxProps> = ({ handleClick, label, type }) => {
  const Icon = iconMap[type];

  return (
    <div className="question-type-div" onClick={() => handleClick(type)}>
      <div className="icon-wrapper">
        <Icon />
        <span className="plus">+</span>
      </div>
      <div className="mt10px">{label}</div>
    </div>
  );
};

export default QuestionBox;
