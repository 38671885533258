import { TextField, Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { Label } from 'sharedComponents/Label';
import { ConnectMarketingInputAndButtonProps } from '../types';
import { ConnectMarketingInputAndButtonDataTestIds } from 'data-testids/ConnectDataTestIds';
import { useTheme } from '@mui/material/styles';

export function ConnectMarketingInputAndButton({
  onSubmit,
  isLoading,
  email,
  setEmail,
  isActive,
}: ConnectMarketingInputAndButtonProps): React.ReactElement {
  const theme = useTheme();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <ConnectMarketingInputAndButtonContainer>
      <InputLabelBox>
        <Label labelText={'Enter your personal email'} sx={labelStyles(isMobile, theme)} />
        <StyledTextField
          inputProps={{ style: { padding: 0 } }}
          placeholder={'Personal email address'}
          onChange={(evt) => setEmail(evt.target.value)}
          value={email}
          data-testid={ConnectMarketingInputAndButtonDataTestIds.EMAIL_INPUT}
        />
      </InputLabelBox>
      <Divider />
      <PrimaryButton
        onClick={onSubmit}
        size="large"
        isLoading={isLoading}
        disabled={!isActive}
        dataTestId={ConnectMarketingInputAndButtonDataTestIds.SIGN_UP_BUTTON}
        sx={primaryButtonStyles(theme)}
      >
        Create your profile
      </PrimaryButton>
    </ConnectMarketingInputAndButtonContainer>
  );
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  color: '#6B7280',
  lineHeight: '20px',
  padding: 'none',
  fontStyle: 'normal',
  fontSize: '16px',
  '& fieldset': { border: 'none' },
  width: '100%',

  [theme.breakpoints.down('md')]: {
    fontSize: '11.333px',
  },
}));

const ConnectMarketingInputAndButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: `${theme.spacing(1)} solid ${theme.palette.primary.fill}`,
  borderRadius: theme.shape.borderRadius * 6,
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 8px 0px 24px',
  minHeight: '86px',
  gap: theme.spacing(3),

  [theme.breakpoints.between('sm', 'lg')]: {
    minHeight: '70px',
    gap: theme.spacing(2),
  },

  [theme.breakpoints.down('sm')]: {
    minHeight: '65px',
    maxHeight: '65px',
    gap: theme.spacing(1),
  },
}));

const InputLabelBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Divider = styled(Box)(({ theme }) => ({
  border: '0.5px solid #E5E7EB',
  color: '#6B7280',

  [theme.breakpoints.down('md')]: {
    height: '22.67px',
  },
  [theme.breakpoints.up('md')]: {
    height: '32px',
  },
}));

const labelStyles = (isMobile, theme) => ({
  color: theme.palette.black.darker,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: isMobile ? '8.5px' : '12px',
  paddingBottom: 'none',
  padding: 'none',
});

const primaryButtonStyles = (theme) => ({
  color: theme.palette.white.main,
  maxWidth: '180px',
  maxHeight: '53px',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '16px',
  borderRadius: theme.shape.borderRadius,
  padding: '13.393px 21.429px',
  whiteSpace: 'nowrap',
  gap: theme.spacing(1),

  [theme.breakpoints.down('lg')]: {
    maxWidth: '120px',
    maxHeight: '40px',
    fontSize: '11.33px',
    borderRadius: '11.33px',
    padding: `10.625px ${theme.spacing(1)}`,
    gap: theme.spacing(0.75),
    background: '#0071BA',
    display: 'inline-flex',
    alignItems: 'center',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '11.33px',
    lineHeight: '18.7px',
    gap: theme.spacing(0.5),
  },
});
