import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import { ConnectGatedActions } from 'features/Connect/utils/connectEnums';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { Actions, School } from 'types/connectTypes';
import auth from 'utils/auth';
import { ExpressInterestButtonProps } from '../types';
import {
  CONNECT_ACTION_LABEL,
  connectRedirectUrls,
  connectUrlParams,
} from 'features/Connect/features/JobDiscovery/constants';
import {
  checkUserProfileCompletion,
  useConnectActions,
} from 'features/Connect/features/ExpressInterest/utils';
import { ConnectActionButtonsDataTestIds } from 'data-testids/ConnectDataTestIds';
import { buildUrl } from 'utils/util';
import { useStateParam } from 'features/Connect/features/ConnectStateCodeContextProvider';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';

export const ExpressInterestButton: React.FC<ExpressInterestButtonProps> = ({
  school,
  actions,
  sx,
  buttonText = CONNECT_ACTION_LABEL,
  fontSize = '14px',
}) => {
  const stateCode = useStateParam();
  const history = useHistory();
  const user = auth.getUser();
  const { handleConnectUserAction } = useConnectActions();

  const hasExpressedInterest = () => {
    return actions.hasSchoolBeenContacted(school.nces_id) || school?.hasExpressedInterest;
  };

  const expressInterestOnClickHandler = async (
    school: School,
    actions: Actions,
    stateCode: string
  ): Promise<void> => {
    const urlParamsToAdd = {
      [connectUrlParams.REDIRECT]: CONNECT_JOBBOARD_STATES[stateCode].urlParam,
      [connectUrlParams.SCHOOL]: school.nces_id,
      [connectUrlParams.ACTION]: ConnectGatedActions.EXPRESS_INTEREST,
    };
    if (!user) {
      history.push(buildUrl(connectRedirectUrls.SIGN_UP, urlParamsToAdd));
    } else if (!user?.preference?.nimble_connect_profile?.is_completed) {
      if (
        !checkUserProfileCompletion(
          user,
          history,
          stateCode,
          school,
          ConnectGatedActions.EXPRESS_INTEREST
        )
      ) {
        return;
      }
    } else {
      actions.setReadyToConnectModalIsOpen(false); // ensure modal is closed to prevent double render bug
      actions.setExpressInterestModalIsOpen(true);
      actions.setSelectedSchool(school);
    }
  };

  const expressInterestCallbackFunction = async () => {
    await expressInterestOnClickHandler(school, actions, stateCode);
  };

  return (
    <PrimaryButton
      size="small"
      color="black"
      disabled={hasExpressedInterest()}
      startIcon={hasExpressedInterest() && <CheckCircleIcon />}
      dataTestId={ConnectActionButtonsDataTestIds.EXPRESS_INTEREST_BUTTON}
      sx={{
        ...buttonStyles,
        ...sx,
      }}
      onClick={async () => {
        await handleConnectUserAction(
          user,
          stateCode,
          ConnectGatedActions.EXPRESS_INTEREST,
          expressInterestCallbackFunction,
          school
        );
      }}
    >
      <Typography noWrap style={{ fontSize: fontSize, fontWeight: 600 }}>
        {hasExpressedInterest() ? 'Expressed Interest' : buttonText}
      </Typography>
    </PrimaryButton>
  );
};

const buttonStyles = {
  borderRadius: '8px',
  height: '44px',
  padding: '14px',
};
