import axios from 'axios';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';

import { appStatusType, preferenceCategories, USStates } from '../utils/enums';
import {
  clearAll,
  fetchEmailTemplates,
  getInitialFilters,
  resetFilters,
  selectAll,
  updateExperienceState,
} from '../utils/statusviewutils';
import {
  deleteQueryStringValue,
  getQueryStringValue,
  setQueryStringValue,
  setQueryStringWithoutPageReload,
} from 'utils/util';
import { Category, District, School } from 'types/types';
import { Candidate } from 'types';
import { fetchProspects, setSortFieldAndDirection } from '../utils/candidatesSearchUtil';

import {
  fetchTalentMarketplaceCandidates,
  fetchTalentMarketplaceCandidatesDebounced,
} from 'utils/marketplaceSearchUtil';

import { fetchDistrictTasks } from '../utils/universalTasksUtil';
import { validateTagUserType } from '../components/CustomTag/util';

import ApplicationStatusesAPI from 'api/applicationStatusesAPI';
import auth from '../utils/auth';
import MarketplaceList from '../components/MarketplaceList/index';
import CandidatesListHeader from '../components/CandidatesList/CandidatesListHeader';
import categoriesAPI from 'api/categoriesAPI';
import customTagAPI from '../api/customTagAPI';
import districtAPI from '../api/districtAPI';
import schoolsAPI from 'api/schoolsAPI';
import Tabs from 'components/Tabs';
import TalentFiltersSidebar from 'features/TalentMarketplace/TalentFiltersSidebar';
import { DistrictTag, TalentState } from './TalentMarketplace.types';
import { Component } from 'react';

const tabsData = [
  { title: 'Not Reviewed', content: 'Not reviewed content', isActive: false },
  { title: 'Not a Fit', content: 'Not a fit content', isActive: false },
  { title: 'Potential Fit', content: 'Potential fit content', isActive: true },
  { title: 'Converted to Prospect', content: 'Converted to prospect', isActive: false },
];

class TalentMarketplace extends Component<RouteComponentProps, TalentState> {
  clearAll: () => void;
  currentTab: number;
  district: District;
  districtId: string;
  fetchTalentMarketplaceCandidates: (startSpinner: boolean, paginate: boolean) => void;
  fetchTalentMarketplaceCandidatesDebounced: () => void;
  fetchDistrictTasks: (on_filter_task: boolean) => void;
  fetchEmailTemplates: () => void;
  fetchProspects: (startSpinner: boolean, paginate: boolean) => void;
  getInitialFilters: (district_id: string) => any; // fix later
  isSchoolUser: boolean;
  resetFilters: () => void;
  selectAll: () => void;
  setSortFieldAndDirection: (sortField: string, orderDir: string, cb: () => void) => void;
  state: TalentState;
  updateExperienceState: (experienceStart: number, experienceEnd: number) => void;
  ignoreLastFetch: boolean;

  constructor(props) {
    super(props);
    this.clearAll = clearAll.bind(this);
    this.district = auth.getUser().profile.district;
    this.districtId = auth.getUser().profile.district.id;
    this.fetchTalentMarketplaceCandidates = fetchTalentMarketplaceCandidates.bind(this);
    this.fetchTalentMarketplaceCandidatesDebounced =
      fetchTalentMarketplaceCandidatesDebounced.bind(this);
    this.fetchDistrictTasks = fetchDistrictTasks.bind(this);
    this.fetchEmailTemplates = fetchEmailTemplates.bind(this);
    this.fetchProspects = fetchProspects.bind(this);
    this.getInitialFilters = getInitialFilters.bind(this);
    this.isSchoolUser = auth.isSchoolUser();
    this.resetFilters = resetFilters.bind(this);
    this.selectAll = selectAll.bind(this);
    this.setSortFieldAndDirection = setSortFieldAndDirection.bind(this);
    this.state = this.getInitialState();
    this.updateExperienceState = updateExperienceState.bind(this);
  }

  getInitialState = (): TalentState => {
    return {
      activeDistrictSchools: [],
      application_type: 'application',
      availableApplicationStatuses: [],
      candidates: [],
      categories: [],
      credentialOptions: [],
      cred_list: [],
      currentTab: 0,
      elasticSearchError: false,
      employment_type_and_hours: [],
      experienceEnd: 30,
      experienceStart: 0,
      fetchCandidatesCompleted: false,
      fetchCandidatesRunning: false,
      grades: [],
      hellosign_templates: [],
      hrbpAssigneeOptions: [],
      hrbp_assignees: [],
      hrbp_assignees_length: 0,
      internal_list: [],
      job_status_list: [],
      job_subject_length: 0,
      job_subject_list: [],
      language_list: [],
      more: false,
      multilingual_school: [],
      newApplicationStatuses: [],
      onboarding_locations: [],
      orderBy: 'years_experience',
      orderDir: 'desc',
      page: 0,
      partialApplication: {},
      partialUser: {},
      preference_categories: [],
      query: '',
      schoolAdminViewingDistrictApp: false,

      showSpinner: false,
      status_list: [],
      statusOptions: [],
      tag_ids: [],
      tag_ids_length: 0,
      task_list: [],
      title_1_school: [],
      totalCandidateCount: 0,
      turnaround_school: [],
      universal_tasks: [],
      visible_templates: [],
    };
  };

  componentDidMount() {
    this.mountingCode();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.unmountingCode();
      this.setState(this.getInitialState(), this.mountingCode);
    }
  }

  componentWillUnmount() {
    this.unmountingCode();
  }

  componentDidUpdate(_prevProps, prevState: TalentState) {
    if (prevState.query !== this.state.query && this.state.query.length !== 1) {
      this.search();
    } else if (prevState.currentTab !== this.state.currentTab) {
      this.search();
    }
  }

  mountingCode = async () => {
    this.ignoreLastFetch = false;
    document.body.classList.add('candidates-list-page');
    document.body.classList.add('candidates');
    document.body.classList.add('new-candidate-line-items');
    const onProspectList = window.location.href.indexOf('prospectlist') !== -1;

    if (onProspectList) {
      document.body.classList.add('prospects');
      this.setProspectFiltersThenSearch();
    } else {
      this.setDefaultSearchParams();
      this.setCandidateFiltersThenSearch();
      categoriesAPI
        .fetchCategories({ districtId: this.districtId })
        .then(this.setInitialCategories);
      this.fetchApplicationStatuses();
      this.fetchCredentialOptions();
      this.fetchDistrictTasks(true);
      this.fetchHellosignTemplates();
      schoolsAPI.fetchActiveSchools(this.districtId).then((activeDistrictSchools) => {
        this.setState({ activeDistrictSchools });
        this.setInitialOnboardingLocations(activeDistrictSchools);
      });

      customTagAPI.fetchCustomTags().then((tags) => {
        const filteredTags = this.filteredDistrictTags(tags);
        this.setInitialTags(filteredTags);
      });

      // spec: "hrbp assignees" filter should include all district super/district admins
      if (auth.isDistrictAdmin(auth.getUser())) {
        districtAPI.getDistrictAndSuperAdmins(this.districtId).then(this.setInitialHrbpAssignees);
      }

      ApplicationStatusesAPI.getAll({
        districtId: this.districtId,
        statusTypes: [
          appStatusType.pre_hiring_pool,
          appStatusType.hiring_pool,
          appStatusType.archived,
          appStatusType.onboarding,
        ],
      }).then((data) => {
        this.setState({ availableApplicationStatuses: data });
      });
    }
  };

  unmountingCode = () => {
    this.ignoreLastFetch = true;
    document.body.classList.remove('candidates-list-page');
    document.body.classList.remove('candidates');
    document.body.classList.remove('prospects');
  };

  setDefaultSearchParams = () => {
    const authUser = auth.getUser();
    if (!window.location.search && authUser.profile.view_all_default_search_params) {
      setQueryStringWithoutPageReload('?' + authUser.profile.view_all_default_search_params);
    }
  };

  setProspectFiltersThenSearch = async () => {
    // fetch district tags and store in state
    const tags = await customTagAPI.fetchCustomTags();
    const filteredTags = this.filteredDistrictTags(tags, true);
    this.setInitialTags(filteredTags);

    await axios
      .get(`/api/candidate-source/`, {
        params: { district_id: this.districtId },
      })
      .then((r) => {
        r.data.unshift({ value: 'none', label: 'N/A' });
        const { search } = this.props.location;
        const params = new URLSearchParams(search);
        const query = params.get('query');

        this.setState(
          {
            query: query || '',
            tag_ids: this.state.tag_ids,
            sources: r.data,
          },
          this.initialSearch
        );
      });
  };

  setCandidateFiltersThenSearch = () => {
    const { search } = this.props.location;
    const searchParams = new URLSearchParams(search);
    const query = searchParams.get('query');
    const daysAgo = searchParams.get('days_ago');

    const {
      cred_list,
      employment_type_and_hours,
      experienceEnd,
      experienceStart,
      grades_list,
      hellosign_templates,
      hrbp_assignees,
      internal_list,
      job_status_list,
      job_subject_list,
      language_list,
      multilingual_school,
      onboarding_locations,
      preference_categories,
      status_list,
      tag_ids,
      task_list,
      title_1_school,
      turnaround_school,
    } = this.getInitialFilters(this.districtId);

    this.setState(
      {
        cred_list,
        days_ago: daysAgo || '',
        employment_type_and_hours,
        experienceEnd,
        experienceStart,
        grades: grades_list,
        hrbp_assignees,
        hellosign_templates,
        internal_list,
        job_status_list,
        job_subject_list,
        language_list,
        multilingual_school,
        onboarding_locations,
        preference_categories,
        query: query || '',
        status_list,
        title_1_school,
        turnaround_school,
        tag_ids,
        task_list,
      },
      this.initialSearch
    );
  };

  startSpinner = () => {
    this.setState({ showSpinner: true });
  };

  stopSpinner = () => {
    this.setState({ showSpinner: false });
  };

  fetchApplicationStatuses = () => {
    axios.get(`/api/application_statuses/`).then((r) => {
      this.setState({ newApplicationStatuses: r.data }, this.setStatusOptions);
    });
  };

  fetchCredentialOptions = () => {
    const stateObj = USStates().filter((state) => state.label === this.district.state)[0];
    axios.get(`/api/state/${stateObj.id}/`).then((r) => {
      const credentialOptionsFormatted = r.data.credential_subjects.map((item) => {
        item['key'] = item.id;
        item['label'] = item.value;
        item['value'] = item.id;
        return item;
      });
      this.setState({ credentialOptions: credentialOptionsFormatted }, this.setCredentialOptions);
    });
  };

  setCredentialOptions = () => {
    const credentialOptions = [...this.state.credentialOptions];
    const credList = getQueryStringValue('f_C');
    if (!credList) this.setState({ cred_list: credentialOptions.map((s) => s.id) });

    this.setState({ credentialOptions });
  };

  setStatusOptions = () => {
    let statusOptions = [...this.state.newApplicationStatuses];
    if (this.isSchoolUser) {
      statusOptions = statusOptions.filter((s) => s.school_admin_view_access === true);
    }

    const candidateStatuses = getQueryStringValue('f_CS');
    if (!candidateStatuses) this.setState({ status_list: statusOptions.map((s) => s.id) });

    this.setState({ statusOptions });
  };

  setInitialOnboardingLocations = (activeDistrictSchools: School[]) => {
    const onboardingLocations = getQueryStringValue('f_OL');

    if (!onboardingLocations) {
      const activeSchoolIds = activeDistrictSchools.map((school) => school.id);
      this.setState({ onboarding_locations: activeSchoolIds });
    }
  };

  setCurrentQuery = (newQuery) => {
    this.setState(newQuery);
  };

  search = _.debounce((paginate = true) => {
    this.setState(
      {
        candidates: [],
        page: 0,
        more: false,
      },
      () => this['fetchTalentMarketplaceCandidates'](true, paginate)
    );
  }, 500);

  initialSearch = (paginate = true) => {
    this['fetchTalentMarketplaceCandidates'](true, paginate);
  };

  setApplicationCountPerCandidate = (candidates) => {
    candidates.forEach((candidate) => {
      candidate['app_count'] = candidate.applications.length;
    });
    return candidates;
  };

  updateMultiSelectFilter = (
    fieldName: string,
    fieldValue: number,
    active: boolean,
    optionsLength: number
  ) => {
    let updatedField;

    if (active) {
      updatedField = this.state[fieldName].filter((e) => e !== fieldValue);
    } else {
      updatedField = this.state[fieldName];
      updatedField.push(fieldValue);
    }

    if (updatedField.length === 0 || updatedField.length === optionsLength) {
      deleteQueryStringValue(fieldName);
    } else {
      setQueryStringValue(fieldName, updatedField.join());
    }

    this.setState(
      (previousState) => ({ ...previousState, [fieldName]: updatedField }),
      this.search
    );
  };

  updateMultiSelectFilterWithMultipleValues = (
    fieldName: string,
    values: string[],
    optionsLength: number
  ) => {
    if (values.length === 0 || values.length === optionsLength) {
      deleteQueryStringValue(fieldName);
    } else {
      setQueryStringValue(fieldName, values.join());
    }

    this.setState((previousState) => ({ ...previousState, [fieldName]: values }), this.search);
  };

  setApplicationForQuickView = (partialUser: Candidate) => {
    this.setState({ partialUser: partialUser });
  };

  updateProspectStatus = (selectedStatus, prospect_id: string) => {
    const updateObject = { status: selectedStatus.value };
    this.updateProspectInState(updateObject, prospect_id);
  };

  updateProspectInState = (updateObject, prospect_id: string) => {
    const candidates = this.state.candidates;
    const candidate = candidates.find((candidate) => candidate.prospect_id === prospect_id);

    if (candidate) {
      for (const property in updateObject) {
        candidate[property] = updateObject[property];
      }
      this.setState({ candidates });
    }
  };

  replaceProspectInState = (prospect: Candidate) => {
    const candidates = this.state.candidates;
    const index = candidates.findIndex((candidate) => candidate.id === prospect.id);

    if (index > -1) {
      candidates[index] = prospect;
      this.setState({ candidates });
    }
  };

  updateSchoolApp = (updatedCandidate: Candidate) => {
    this.updateCandidateInState(updatedCandidate);
  };

  updateCandidateInState = (updatedCandidate: Candidate) => {
    this.setState((prevState) => {
      return {
        candidates: prevState.candidates.map((candidate) =>
          candidate.id === updatedCandidate.id ? { ...candidate, ...updatedCandidate } : candidate
        ),
      };
    });
  };

  refreshCandidateInList = (candidateId: number) => {
    const params = {
      job_status_list: this.state.job_status_list,
      role_id: null,
    };
    axios
      .get(`/api/user/${candidateId}/candidates_list_data/`, { params })
      .then(({ data }) => this.updateCandidateInState(data));
  };

  fetchHellosignTemplates = () => {
    axios.get('/api/hellosign/visible_templates/').then(({ data }) => {
      const visible_templates = data;
      this.setState({ visible_templates });

      const hellosignTemplates = getQueryStringValue('f_HT');

      if (!hellosignTemplates) {
        const hellosign_templates = [];

        visible_templates.forEach(({ template_id }) => {
          hellosign_templates.push(template_id);
          hellosign_templates.push(`-${template_id}`);
        });

        this.setState({ hellosign_templates });
      }
    });
  };

  setInitialCategories = (categories: Category[]) => {
    const job_subject_list = [];
    categories.forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        job_subject_list.push(Number(subcategory.id));
      });
    });

    this.setState({
      categories,
      job_subject_length: job_subject_list.length,
    });

    const jobSubjectList = getQueryStringValue('f_JSU');
    if (!jobSubjectList) {
      this.setState({ job_subject_list });
    }
  };

  setInitialTags = (tags: DistrictTag[]) => {
    const tag_ids = tags.map((tag) => Number(tag.id));

    this.setState({
      tags,
      tag_ids_length: tag_ids.length,
    });

    const tagIds = getQueryStringValue('f_TG');
    if (!tagIds) {
      this.setState({
        tag_ids,
      });
    }
  };

  setInitialHrbpAssignees = (hrbpAssigneesData) => {
    const hrbp_assignees = hrbpAssigneesData.map((assignee) => assignee.id);

    this.setState({
      hrbpAssigneeOptions: hrbpAssigneesData.map((assignee) => ({
        value: assignee.id,
        label: assignee.name,
      })),
      hrbp_assignees_length: hrbpAssigneesData.length,
    });

    const hrbpASsignees = getQueryStringValue('f_HA');
    if (!hrbpASsignees) {
      this.setState({
        hrbp_assignees,
      });
    }
  };

  filteredDistrictTags = (tag_ids: DistrictTag[], isProspectlist = false) => {
    const filteredTags = tag_ids.filter((tag) => {
      if (!isProspectlist && !tag.candidate_type && tag.prospect_type) return false;
      else if (isProspectlist && tag.candidate_type && !tag.prospect_type) return false;

      if (!validateTagUserType(auth.getUser(), tag)) return false;
      return tag;
    });

    return filteredTags;
  };

  updateCandidatesInContainerState = (updatedCandidates: Candidate[]) => {
    this.setState((previousState) => {
      const candidates = previousState.candidates.map((candidate) => {
        const updatedCandidate = updatedCandidates.find((c) => c.id === candidate.id);
        return updatedCandidate ? { ...candidate, ...updatedCandidate } : candidate;
      });

      return { candidates };
    });
  };

  handleTabClick = (event) => {
    this.setState({
      currentTab: Number(event.target.id),
    });
  };

  render() {
    const { search } = this.props.location;
    const searchParams = new URLSearchParams(search);
    const daysAgo = searchParams.get('days_ago');

    return (
      <div className="candidateslist-and-filter-container">
        <TalentFiltersSidebar
          activeDistrictSchools={this.state.activeDistrictSchools}
          clearAll={this.clearAll}
          credentialOptions={this.state.credentialOptions}
          credList={this.state.cred_list}
          experienceEnd={this.state.experienceEnd}
          experienceStart={this.state.experienceStart}
          filterOptions={preferenceCategories()}
          filterValues={this.state.preference_categories}
          grades={this.state.grades}
          multilingualSchool={this.state.multilingual_school}
          selectAll={this.selectAll}
          title1School={this.state.title_1_school}
          turnaroundSchool={this.state.turnaround_school}
          updateExperienceState={this.updateExperienceState}
          updateMultiSelectFilter={this.updateMultiSelectFilter}
          updateMultiSelectFilterWithMultipleValues={this.updateMultiSelectFilterWithMultipleValues}
        />
        <div
          className="candidateslist-container"
          style={{ backgroundColor: 'white', padding: 0, top: '60px' }}
        >
          <CandidatesListHeader
            currentTab={this.state.currentTab}
            daysAgo={daysAgo}
            elasticSearchError={this.state.elasticSearchError}
            onProspectList={false}
            onTalentMarketplace={true}
            onViewByPosting={false}
            query={this.state.query}
            resetFilters={this.resetFilters}
            setCurrentQuery={this.setCurrentQuery}
            totalCandidateCount={this.state.totalCandidateCount}
            defaultSort={this.state.orderBy}
            defaultOrder={this.state.orderDir}
          />
          <Tabs
            currentTab={this.state.currentTab}
            handleTabClick={this.handleTabClick}
            tabsData={tabsData}
          />
          <MarketplaceList
            application_type={this.state.application_type}
            availableApplicationStatuses={this.state.availableApplicationStatuses}
            candidates={this.state.candidates}
            currentTab={this.state.currentTab}
            fetchTalentMarketplaceCandidates={this.fetchTalentMarketplaceCandidates}
            fetchTalentMarketplaceCandidatesDebounced={
              this.fetchTalentMarketplaceCandidatesDebounced
            }
            fetchCandidatesRunning={this.state.fetchCandidatesRunning}
            fetchProspects={this.fetchProspects}
            hasMore={this.state.more}
            isSchoolUser={this.isSchoolUser}
            job_status_list={this.state.job_status_list}
            jobTitle={''}
            newApplicationStatuses={this.state.newApplicationStatuses}
            onProspectList={false}
            onViewByPosting={false}
            orderBy={this.state.orderBy}
            orderDir={this.state.orderDir}
            partialApplication={this.state.partialApplication}
            partialUser={this.state.partialUser}
            refreshCandidateInList={this.refreshCandidateInList}
            search={this.search}
            setApplicationForQuickView={this.setApplicationForQuickView}
            setSortFieldAndDirection={this.setSortFieldAndDirection}
            showSpinner={this.state.showSpinner}
            totalCandidateCount={this.state.totalCandidateCount}
            updateCandidatesInContainerState={this.updateCandidatesInContainerState}
            updateProspectStatus={this.updateProspectStatus}
          />

          <ReactTooltip id="no-fit" effect="solid">
            <span>Not a Fit</span>
          </ReactTooltip>
          <ReactTooltip id="potential-fit" effect="solid">
            <span>Potential Fit</span>
          </ReactTooltip>
          <ReactTooltip id="converted-to-prospect" effect="solid">
            <span>Convert to Prospect</span>
          </ReactTooltip>
        </div>
      </div>
    );
  }
}

export default withRouter(TalentMarketplace);
