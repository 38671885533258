import { useEffect, useState } from 'react';

import { Theme, useMediaQuery, useTheme } from '@mui/material';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';
import OpenInNew from '@mui/icons-material/OpenInNew';

import { SecondaryButton } from 'sharedComponents/Buttons';
import { BlankModal } from 'sharedComponents/Modal';
import { BasicInfoSection } from '../../components/SchoolCard/SchoolCardBasicInfoSection';
import { ExpressInterestButton } from '../Buttons/ExpressInterestButton';
import { VacancyRow } from './VacancyRow';

import { ReadyToConnectModalProps } from 'types/connectTypes';
import { simpleBrowserUrlFormatter } from 'utils/util';
import { CONNECT_ACTION_LABEL } from 'features/Connect/features/JobDiscovery/constants';

export const ReadyToConnectModal: React.FC<ReadyToConnectModalProps> = ({
  isOpen,
  handleClose,
  school,
  vacancy,
  actions,
}) => {
  const [isFormalLinkButtonDisabled, setIsFormalLinkButtonDisabled] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const handleFormalApplicationClick = () => {
    try {
      const formattedLink = simpleBrowserUrlFormatter(vacancy.job_url);
      window.open(formattedLink, '_blank', 'noopener noreferrer');
    } catch (e) {
      console.error(`Error formatting job url link ${vacancy.job_url}`, e);
    }
  };

  useEffect(() => {
    if (!vacancy.job_url) {
      setIsFormalLinkButtonDisabled(true);
    }
  }, [vacancy.job_url]);

  return (
    <BlankModal
      open={isOpen}
      onClose={handleClose}
      sx={{
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        padding: isMobile ? '16px' : '24px',
        borderRadius: '8px',
      }}
      header={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: '16px',
          }}
        >
          <Typography
            sx={{
              color: '#101828',
              fontFamily: 'Open Sans',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '28px',
            }}
          >
            Get ready to connect!
          </Typography>
          <CloseButton onClick={handleClose}>
            <CloseIcon htmlColor="#667085" />
          </CloseButton>
        </Box>
      }
      body={
        <Box gap="8px">
          <Box
            sx={{
              display: 'flex',
              padding: isMobile ? '16px' : '24px',
              flexDirection: 'column',
              gap: '8px',
              borderRadius: '8px',
              border: '1px solid #B1ECDB',
              backgroundColor: '#EFFAF8AD',
              margin: '16px 0',
            }}
          >
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 500,
                Color: '#101828',
              }}
            >
              {school?.name}
            </Typography>
            <BasicInfoSection school={school} isInterestModal />
            <VacancyRow vacancy={vacancy} isConnectModalView />
          </Box>
          <Box margin="20px 0" width="100%" alignContent="center">
            <Typography
              sx={{
                color: '#3A3A3A',
                fontFamily: 'Open Sans',
                fontSize: '15x',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '28px',
                margin: '16px 0px 4px',
              }}
            >
              Are you sure you don&apos;t want to connect here?
            </Typography>
            <Box component="ul" sx={{ margin: '4px 13px', padding: '0 12px', fontSize: '14px' }}>
              <Box component="li">
                Connect directly with the principal and schedule a chat - it takes just 3 minutes!
              </Box>
              <Box component="li">
                Get quick feedback on whether it&apos;s a fit before submitting a formal application
              </Box>
            </Box>
          </Box>
          <Stack direction="column" sx={{ margin: '15px 0' }}>
            <ExpressInterestButton
              buttonText={CONNECT_ACTION_LABEL + ' 🎉'}
              fontSize="16px"
              school={school}
              actions={actions}
              sx={{
                padding: '10px 18px',
                borderRadius: '8px',
                fontWeight: 600,
              }}
            />
            <SecondaryButton
              endIcon={<OpenInNew fontSize="large" />}
              onClick={handleFormalApplicationClick}
              disabled={isFormalLinkButtonDisabled}
              sx={buttonStyles(theme)}
            >
              Link to formal application
            </SecondaryButton>
          </Stack>
        </Box>
      }
    />
  );
};

const CloseButton = styled(Button)({
  justifyItems: 'end',
  backgroundColor: 'transparent',
  borderStyle: 'none',
  height: '24px',
  padding: 0,
  minWidth: '5px',
});

const buttonStyles = (theme: Theme) => ({
  padding: '10px 18px',
  marginTop: theme.spacing(1.5),
  color: theme.palette.text.secondary,
  border: `1px solid ${theme.palette.gray.mediumLight}`,
  borderRadius: theme.shape.borderRadius * 0.5,
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.fontWeightMediumBold,
  height: '44px',
  '&:hover': {
    backgroundColor: theme.palette.white.medium,
    border: `1px solid ${theme.palette.text.secondary}`,
  },
  '& .MuiTouchRipple-root': {
    color: theme.palette.gray.mediumLight,
  },
});
