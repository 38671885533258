import { Box, styled, Typography } from '@mui/material';

export const CloseButtonAndHelpText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'end',
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  minHeight: '300px',
  marginTop: theme.spacing(1),
}));

export const CTAButtonsContainer = styled(Box)({
  alignItems: 'end',
  display: 'flex',
  justifyContent: 'end',
  width: '100%',
});

export const CTARow = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
});

export const DenialFormContainer = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'end',
});

export const DetailsContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  overflow: 'auto',
}));

export const ErrorText = styled(Box)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
});

export const FormContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'end',
});

export const FormOptions = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.medium,
  borderRadius: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export const JobToMergeIntoTitle = styled(Box)({
  width: '50%',
});

export const Key = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMediumBold,
}));

export const LoadingSpinnerContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '300px',
});

export const LowerBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3),
  width: '100%',
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.darker,
}));

export const Right = styled(JobToMergeIntoTitle)({
  display: 'flex',
  justifyContent: 'end',
});

export const ReturnBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    paddingRight: theme.spacing(3),
  },
}));

export const Row = styled(Box)(({ theme }) => ({
  display: 'block',
  paddingBottom: theme.spacing(2),
}));

export const SuccessCTAContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(3),
  width: '100%',
}));

export const SuccessContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const SuccessMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: theme.spacing(1.5),
}));

export const SuccessTitle = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

export const TitleContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

export const Value = styled('span')({});
