import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { StyledToggleButton } from './styles';
import { ToggleButtonsProps } from './types';

export enum ToggleButtonAlignment {
  LEFT_TOGGLE_ACTIVE = 0,
  RIGHT_TOGGLE_ACTIVE = 1,
}

export const ToggleButtons: React.FC<ToggleButtonsProps> = ({
  currentlySelected,
  onToggle,
  options,
  dataTestId,
}) => {
  const [alignment, setAlignment] = useState(
    currentlySelected ? currentlySelected : ToggleButtonAlignment.LEFT_TOGGLE_ACTIVE
  );
  const history = useHistory();

  const handleChange = (_event: React.MouseEvent<HTMLElement>, newAlignment: number) => {
    setAlignment(newAlignment);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Button group"
      data-testid={dataTestId}
    >
      {options.map((option) => {
        return (
          <StyledToggleButton
            key={option.id}
            value={option.id}
            selected={null || currentlySelected === option.id}
            onClick={() => {
              if (currentlySelected === option.id) return;

              onToggle(option);
              if (option.href) {
                history.push(option.href);
              }
            }}
          >
            {option.title}
          </StyledToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};
