import { useHistory } from 'react-router';
import { useTheme } from '@mui/material';

import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';
import { SuccessMessagingProps } from '../types';
import { SuccessContainer, SuccessCTAContainer, SuccessMessage } from './styles';
import { ATSJobRequestUpdateTestIds } from 'data-testids/ATS';

export const SuccessMessaging: React.FC<SuccessMessagingProps> = ({
  jobId,
  jobTitle,
  message,
  onClose,
  shouldShowSecondButton,
}) => {
  const theme = useTheme();
  const history = useHistory();

  const handleVisitClick = () => {
    history.push(`/district/jobedit/${jobId}/`);
    return onClose();
  };

  return (
    <SuccessContainer>
      <SuccessMessage data-testid={ATSJobRequestUpdateTestIds.SUCCESS_MESSAGE}>
        {message}
      </SuccessMessage>
      <SuccessCTAContainer>
        <SecondaryButton
          dataTestId={ATSJobRequestUpdateTestIds.SUCCESS_BACK_TO_JOBS_LIST_BUTTON}
          sx={{ marginRight: theme.spacing(2) }}
          onClick={onClose}
        >
          Back to job listings
        </SecondaryButton>
        {shouldShowSecondButton && (
          <PrimaryButton
            dataTestId={ATSJobRequestUpdateTestIds.SUCCESS_EDIT_BUTTON}
            onClick={handleVisitClick}
          >{`Continue to edit ${jobTitle}`}</PrimaryButton>
        )}
      </SuccessCTAContainer>
    </SuccessContainer>
  );
};
