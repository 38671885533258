import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { nimbleTheme } from 'theme';

export const indianaTheme = createTheme({
  ...nimbleTheme,
  palette: {
    ...nimbleTheme.palette,
    primary: {
      main: '#0071B0',
      dark: '#00517E',
    },
  },
});

export const missouriTheme = createTheme({
  ...nimbleTheme,
  palette: {
    ...nimbleTheme.palette,
    primary: {
      main: '#0071B0',
      dark: '#00517E',
      fill: '#D1E8F5',
    },
  },
});

export const tennesseeTheme = createTheme({
  ...nimbleTheme,
  palette: {
    ...nimbleTheme.palette,
    primary: {
      main: '#1E376D',
      light: '#3643C5',
      dark: '#090E42',
      fill: '#D0DEFC',
    },
  },
});

export const StateThemes = {
  INDIANA: indianaTheme,
  MISSOURI: missouriTheme,
  TENNESSEE: tennesseeTheme,
};

export const getTheme = (jobboardState: string | null = null) => {
  if (jobboardState && StateThemes[jobboardState]) {
    return createTheme(deepmerge(nimbleTheme, StateThemes[jobboardState]));
  }
  return nimbleTheme;
};
