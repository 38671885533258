import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import applicationsAPI from 'api/applicationsAPI';
import roleSearchAPI from 'api/roleSearchAPI';
import usersAPI from 'api/usersAPI';
import { CandidateApplicationConnectData, HasApplicationStatus } from './types';
import { User, Job } from 'types';

export const fetchActiveOpenRoles = (inputValue: string): Promise<Job[]> => {
  return roleSearchAPI.searchActiveOpenDistrictRoles(inputValue).then((res) => res.results);
};

export const useQuerySearchActiveOpenRoles = (
  inputValue: string,
  options: Partial<UseQueryOptions<Job[]>> = {}
): UseQueryResult<Job[]> =>
  useQuery({
    ...options,
    queryKey: ['activeOpenSearchResults', inputValue],
    queryFn: () => fetchActiveOpenRoles(inputValue),
  });

export const fetchCandidate = (candidateId: number): Promise<User> => {
  return usersAPI.fetchUser(candidateId).then((res) => res);
};

export const useQueryFetchCandidateForConnectATSIntegration = (
  candidateId: number
): UseQueryResult<User> => {
  return useQuery({
    queryKey: ['candidate', candidateId],
    queryFn: () => fetchCandidate(candidateId),
  });
};

export const fetchIfCandidateHasApplicationForRole = (
  roleId: number,
  candidateId: number
): Promise<HasApplicationStatus> => {
  return applicationsAPI.checkIfCandidateHasApplication(candidateId, roleId).then((res) => {
    return res;
  });
};

export const useQueryCheckIfCandidateHasApplication = (
  candidateId: number,
  roleId: number,
  enabled: boolean
): UseQueryResult<HasApplicationStatus> => {
  const queryKeyToUse = ['roleId', roleId, 'candidateId', candidateId];

  return useQuery({
    queryKey: queryKeyToUse,
    queryFn: () => fetchIfCandidateHasApplicationForRole(roleId, candidateId),
    enabled,
  });
};

export const createApplicationFromConnect = (
  payload: CandidateApplicationConnectData
): Promise<AxiosResponse<unknown, unknown>> => {
  return applicationsAPI.importCandidateFromConnect(payload);
};
