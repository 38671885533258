import { useState } from 'react';
import { CircularProgress, Typography, useTheme } from '@mui/material';

import { ApprovalForm } from './components/ApprovalForm';
import { CTAButtons } from './components/CTAButtons';
import {
  Content,
  CTAButtonsContainer,
  LoadingSpinnerContainer,
  ModalTitle,
  SuccessTitle,
} from './components/styles';
import { CloseButton } from 'sharedComponents/Buttons';
import { DenialForm } from './components/DenialForm';
import { JobRequestDetails } from './components/JobRequestDetails';
import {
  JobRequestModalProps,
  RequestApprovalState,
  RequestDenialState,
  RequestState,
} from './types';
import { Modal } from 'sharedComponents/Modal';
import { ATSJobRequestUpdateTestIds } from 'data-testids/ATS';
import {
  useArchiveRequestMutation,
  useConvertToJobMutation,
  useMergeRolesMutation,
} from './mutations';
import { SuccessMessaging } from './components/SuccessMessaging';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { JobviewJob } from 'types';
import { useCreateReturnNoteMutation } from './mutations';
import { roleStatusByValue } from 'utils/typedEnums';
import { JobRequestAction, JobRequestFormKind } from './enum';
import { ToggleButtonAlignment } from 'sharedComponents/Buttons/ToggleButtons';

export const JobRequestModal: React.FC<JobRequestModalProps> = ({
  handleSetErrorToast,
  filterRequestFromJobsList,
  isJobRequestModalOpen,
  isLoading,
  onClose,
  requester,
  resetFilters,
  role,
  updateRoleStatusOnJobsList,
  initialAction,
}) => {
  const [currentForm, setCurrentForm] = useState<RequestState | null>(() => {
    if (initialAction === undefined) return null;

    if (initialAction === JobRequestAction.return || initialAction === JobRequestAction.archive) {
      return {
        kind: JobRequestFormKind.denial,
        requestDenialAction: initialAction,
        toggleId:
          initialAction === JobRequestAction.archive
            ? ToggleButtonAlignment.LEFT_TOGGLE_ACTIVE
            : ToggleButtonAlignment.RIGHT_TOGGLE_ACTIVE,
      };
    }

    if (initialAction === JobRequestAction.merge || initialAction === JobRequestAction.create) {
      return {
        kind: JobRequestFormKind.approval,
        requestApprovalAction: initialAction,
        toggleId:
          initialAction === JobRequestAction.create
            ? ToggleButtonAlignment.LEFT_TOGGLE_ACTIVE
            : ToggleButtonAlignment.RIGHT_TOGGLE_ACTIVE,
      };
    }

    return null;
  });

  const theme = useTheme();
  const modalSx = {
    padding: theme.spacing(6),
    borderRadius: theme.spacing(2),
    overflow: 'auto',
    maxHeight: 'calc(100vh - 110px)',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  };

  const mergeRoleMutation = useMergeRolesMutation({
    onError: () => handleSetErrorToast(),
  });

  const convertRoleMutation = useConvertToJobMutation({
    onError: () => handleSetErrorToast(),
  });

  const returnNoteMutation = useCreateReturnNoteMutation({
    onError: () => handleSetErrorToast(),
  });

  const archiveMutation = useArchiveRequestMutation({
    onMutate: () => updateRoleStatusOnJobsList(role.id, roleStatusByValue.archived),
    onError: () => handleSetErrorToast(),
    onSuccess: () => resetFilters(),
  });

  const {
    isLoading: isMergePending,
    isSuccess: isMergeSuccess,
    variables: mergeVariables,
  } = mergeRoleMutation;

  const {
    isLoading: isCreatePending,
    isSuccess: isCreateSuccess,
    variables: createVariables,
  } = convertRoleMutation;

  const { isSuccess: isReturnSuccess, isLoading: isReturnPending } = returnNoteMutation;

  const { isSuccess: isArchiveSuccess, isLoading: isArchivePending } = archiveMutation;

  const onDenyClick = () => {
    const nextState: RequestDenialState = {
      kind: JobRequestFormKind.denial,
      requestDenialAction: JobRequestAction.archive,
      toggleId: ToggleButtonAlignment.LEFT_TOGGLE_ACTIVE,
    };

    return setCurrentForm(nextState);
  };

  const onApprovalClick = () => {
    const nextState: RequestApprovalState = {
      kind: JobRequestFormKind.approval,
      requestApprovalAction: JobRequestAction.create,
      toggleId: ToggleButtonAlignment.LEFT_TOGGLE_ACTIVE,
    };
    return setCurrentForm(nextState);
  };

  const onInnerFormClose = () => setCurrentForm(null);

  const handleDenialSubmit = (noteText?: string) => {
    if (currentForm.kind === JobRequestFormKind.denial) {
      if (currentForm.requestDenialAction === JobRequestAction.archive) {
        archiveMutation.mutate({ roleId: role.id });
      } else if (currentForm.requestDenialAction === JobRequestAction.return && noteText) {
        returnNoteMutation.mutate({ roleId: role.id, noteText });
      }
    }
  };

  const handleDenialButtonToggle = () => {
    if (
      currentForm.kind === JobRequestFormKind.denial &&
      currentForm.requestDenialAction === JobRequestAction.archive
    ) {
      setCurrentForm({
        kind: JobRequestFormKind.denial,
        requestDenialAction: JobRequestAction.return,
        toggleId: ToggleButtonAlignment.RIGHT_TOGGLE_ACTIVE,
      });
    } else {
      setCurrentForm({
        kind: JobRequestFormKind.denial,
        requestDenialAction: JobRequestAction.archive,
        toggleId: ToggleButtonAlignment.LEFT_TOGGLE_ACTIVE,
      });
    }
  };

  const handleApprovalButtonToggle = () => {
    if (currentForm.kind === JobRequestFormKind.approval) {
      const nextAction =
        currentForm.requestApprovalAction === JobRequestAction.create
          ? JobRequestAction.merge
          : JobRequestAction.create;

      setCurrentForm({
        kind: JobRequestFormKind.approval,
        requestApprovalAction: nextAction,
        toggleId:
          nextAction === JobRequestAction.create
            ? ToggleButtonAlignment.LEFT_TOGGLE_ACTIVE
            : ToggleButtonAlignment.RIGHT_TOGGLE_ACTIVE,
      });
    }
  };

  const handleMergeSubmit = (roleToMergeInto: JobviewJob) => {
    mergeRoleMutation.mutate({ role, roleToMergeInto });
  };

  const handleCreateSubmit = (title: string, templateId?: number) => {
    convertRoleMutation.mutate({ title, requestId: role.id, templateId });
  };

  const isSuccess = isMergeSuccess || isCreateSuccess || isReturnSuccess || isArchiveSuccess;

  const getSuccessMessage = () => {
    if (currentForm.kind === JobRequestFormKind.approval) {
      return currentForm.requestApprovalAction === JobRequestAction.merge
        ? `Request ${mergeVariables.role.title} has been merged into ${mergeVariables.roleToMergeInto.title}`
        : `Job ${createVariables.title} has been created`;
    } else {
      return currentForm.requestDenialAction === JobRequestAction.archive
        ? 'Request has been archived'
        : 'Request has been returned with note';
    }
  };

  const getTitleToRender = () => {
    if (isSuccess) {
      return (
        <SuccessTitle>
          <CheckCircleIcon color="primary" /> &nbsp;
          <Typography variant="h5" sx={{ paddingLeft: theme.spacing(0.5) }}>
            Success!
          </Typography>
        </SuccessTitle>
      );
    } else {
      return (
        <ModalTitle variant="h2" data-testid={ATSJobRequestUpdateTestIds.MODAL_TITLE}>
          Job request
        </ModalTitle>
      );
    }
  };

  const primaryButtonTitle =
    currentForm?.kind === JobRequestFormKind.approval &&
    currentForm.requestApprovalAction === JobRequestAction.create
      ? createVariables?.title
      : mergeVariables?.roleToMergeInto.title;

  const jobId =
    currentForm?.kind === JobRequestFormKind.approval &&
    currentForm.requestApprovalAction === JobRequestAction.create
      ? createVariables?.requestId
      : mergeVariables?.roleToMergeInto.id;

  const handleClose = () => {
    const isMergeForm =
      currentForm?.kind === JobRequestFormKind.approval &&
      currentForm.requestApprovalAction === JobRequestAction.merge;

    if (isMergeForm) {
      filterRequestFromJobsList(role.id);
    }

    onClose();
  };

  return (
    <Modal
      bodySx={modalSx}
      closeButton={
        <>
          {!isSuccess && (
            <CloseButton
              dataTestId={ATSJobRequestUpdateTestIds.MODAL_CLOSE_BUTTON}
              onClick={() => onClose()}
            />
          )}
        </>
      }
      onClose={onClose}
      open={isJobRequestModalOpen}
      title={getTitleToRender()}
    >
      {isLoading && (
        <LoadingSpinnerContainer data-testid={ATSJobRequestUpdateTestIds.LOADING_SPINNER}>
          <CircularProgress />
        </LoadingSpinnerContainer>
      )}
      {!isLoading && !isSuccess && (
        <Content>
          <JobRequestDetails requester={requester} id={role.id} />

          {currentForm !== null && (
            <>
              {currentForm.kind === JobRequestFormKind.approval && (
                <ApprovalForm
                  action={currentForm.requestApprovalAction}
                  currentlySelectedToggle={currentForm.toggleId}
                  hasPendingMutation={isCreatePending || isMergePending}
                  onApprovalClick={onApprovalClick}
                  onCreateSubmit={handleCreateSubmit}
                  onInnerFormClose={onInnerFormClose}
                  onMergeSubmit={handleMergeSubmit}
                  onToggle={handleApprovalButtonToggle}
                />
              )}
              {currentForm.kind === JobRequestFormKind.denial && (
                <DenialForm
                  currentlySelectedToggle={currentForm.toggleId}
                  onDenialSubmit={handleDenialSubmit}
                  onInnerFormClose={onInnerFormClose}
                  onToggle={handleDenialButtonToggle}
                  action={currentForm?.requestDenialAction}
                  hasPendingMutation={isReturnPending || isArchivePending}
                />
              )}
            </>
          )}
        </Content>
      )}
      {isSuccess && currentForm !== null && (
        <SuccessMessaging
          shouldShowSecondButton={currentForm.kind === JobRequestFormKind.approval}
          jobId={jobId}
          jobTitle={primaryButtonTitle}
          message={getSuccessMessage()}
          onClose={handleClose}
        />
      )}

      {currentForm === null && (
        <CTAButtonsContainer>
          <CTAButtons
            isLoading={isLoading}
            onApproveClick={onApprovalClick}
            onDenyClick={onDenyClick}
          />
        </CTAButtonsContainer>
      )}
    </Modal>
  );
};
