import { useState } from 'react';
import { Autocomplete, Box, TextField, Typography, useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { ErrorText, JobToMergeIntoTitle, LowerBox, Right } from './styles';
import { calculateStatusColor, getStatusText } from 'components/CreateJobOrTemplateModal/utils';
import { useQueryGetJobsBySearchInput } from '../queries';
import { MergeSubFormProps } from '../types';
import useDebounce from '../utils';
import { ATSJobRequestUpdateTestIds } from 'data-testids/ATS';

export const MergeSubForm: React.FC<MergeSubFormProps> = ({ hasComboboxError }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useDebounce(searchTerm, 500);

  const theme = useTheme();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { data: searchResults, isLoading } = useQueryGetJobsBySearchInput(debouncedSearch);

  return (
    <LowerBox data-testid={ATSJobRequestUpdateTestIds.MERGE_SUBFORM}>
      <Typography
        sx={{ color: theme.palette.text.tertiary, paddingBottom: theme.spacing(1) }}
        data-testid={ATSJobRequestUpdateTestIds.MERGE_ENTER_HELP_TEXT}
      >
        Merge job to:
      </Typography>
      <Controller
        name="jobToMergeInto"
        control={control}
        rules={{ required: 'Please select a job' }}
        render={({ field }) => {
          const { onChange } = field;

          return (
            <Autocomplete
              {...field}
              getOptionLabel={(option) => option.title || ''}
              options={searchResults || []}
              onChange={(_e, data) => {
                return onChange(data);
              }}
              loading={isLoading}
              renderOption={(props, option) => (
                <Box
                  {...props}
                  component="li"
                  sx={{
                    '.status': {
                      color: calculateStatusColor(option.status, option.active_status),
                    },
                    display: 'flex',
                  }}
                  key={option.id}
                >
                  <JobToMergeIntoTitle>{option.title}</JobToMergeIntoTitle>
                  <Right className="status">
                    {getStatusText(option.status, option.active_status)}
                  </Right>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  placeholder="Type or select job to merge request into"
                  {...params}
                  sx={{ backgroundColor: theme.palette.white.main }}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  error={Boolean(errors?.jobToMergeInto?.message)}
                />
              )}
            />
          );
        }}
      />
      {hasComboboxError && (
        <ErrorText data-testid={ATSJobRequestUpdateTestIds.MERGE_ERROR_TEXT}>
          {errors?.jobToMergeInto?.message}
        </ErrorText>
      )}
      <Typography
        sx={{ color: theme.palette.text.tertiary, paddingTop: theme.spacing(1) }}
        data-testid={ATSJobRequestUpdateTestIds.REQUEST_HELP_TEXT}
      >
        Note: This job request will take on characteristics of the job it is being merged into. Only
        internal notes will be saved.
      </Typography>
    </LowerBox>
  );
};
