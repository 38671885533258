import { Typography, Stack, Link, styled, Theme } from '@mui/material';
import { BlankModal } from 'sharedComponents/Modal';
import { BlankModalProps } from 'sharedComponents/Modal/types';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { useTheme } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';

interface UpdateResumeModalProps extends Omit<BlankModalProps, 'header' | 'body'> {
  onUpdate: () => void;
  onCancel: () => void;
}

export const UpdateResumeConfirmationModal: React.FC<UpdateResumeModalProps> = ({
  open,
  onClose,
  onUpdate,
  onCancel,
}) => {
  const theme = useTheme();
  const modalHeader = (
    <HeaderContainerStack direction={'row'}>
      <ErrorOutline sx={ErrorIconStyles} />
      <HeaderText>This will update your resume across Nimble</HeaderText>
    </HeaderContainerStack>
  );

  const modalBody = (
    <BodyContainerStack spacing={4}>
      <Stack direction="column" justifyContent="center" gap={2}>
        <BodyText>This includes the Nimble ATS as well as any Nimble state job boards.</BodyText>
        <BodyText>
          Please visit your{' '}
          <Link
            href="/candidate-dashboard"
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
            color="inherit"
          >
            application dashboard
          </Link>{' '}
          to view any active applications that will be updated.
        </BodyText>
      </Stack>
      <Stack direction="row" justifyContent="center" gap={2}>
        <SecondaryButton
          color="black"
          size="extraLarge"
          onClick={onCancel}
          data-testid="cancel-button"
          sx={CancelButtonStyles(theme)}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          color="black"
          size="extraLarge"
          onClick={onUpdate}
          data-testid="update-resume-button"
          sx={UpdateButtonStyles(theme)}
        >
          Update Resume
        </PrimaryButton>
      </Stack>
    </BodyContainerStack>
  );

  return (
    <BlankModal
      header={modalHeader}
      body={modalBody}
      open={open}
      onClose={onClose}
      dataTestId="connect-profile-update-resume-confirmation-modal"
      sx={ModalStyles(theme)}
    />
  );
};

UpdateResumeConfirmationModal.displayName = 'UpdateResumeModal';

const HeaderContainerStack = styled(Stack)(({ theme }) => ({
  textAlign: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}));

const BodyContainerStack = styled(Stack)(() => ({
  textAlign: 'center',
  justifyContent: 'center',
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.h2.fontSize,
  lineHeight: '30px',
  color: theme.palette.gray.dark,
  letterSpacing: '0.15px',
  textAlign: 'center',
  fontFeatureSettings: "'liga' off, 'clig' off",
}));

const BodyText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.heading7.fontSize,
  lineHeight: theme.spacing(3),
  color: theme.palette.text.secondary,
  alignSelf: 'center',
  letterSpacing: '0.15px',
  paragraph: '14px',
  margin: 0,
}));

const ModalStyles = (theme: Theme) => ({
  alignSelf: 'center',
  borderRadius: theme.shape.borderRadius * 1,
  justifySelf: 'center',
  maxWidth: '415px',
  padding: theme.spacing(6),
  margin: 'auto',

  [theme.breakpoints.down('md')]: {
    width: '96%',
    padding: theme.spacing(3),
  },
});

const ErrorIconStyles = (theme: Theme) => ({
  color: theme.palette.warning.dark,
  margin: '4px 0 0 6px',
});

const CancelButtonStyles = (theme: Theme) => ({
  borderRadius: theme.shape.borderRadius * 0.5,
  fontWeight: theme.typography.fontWeightMedium,
});

const UpdateButtonStyles = (theme: Theme) => ({
  borderRadius: theme.shape.borderRadius * 0.5,
  fontWeight: theme.typography.fontWeightMedium,
});
