import React from 'react';
import { useTheme } from '@mui/material';

import { ATSJobRequestUpdateTestIds } from 'data-testids/ATS';
import { CTAButtonsProps } from '../types';
import { CTARow } from './styles';
import { PrimaryButton, TextButton } from 'sharedComponents/Buttons';

export const CTAButtons: React.FC<CTAButtonsProps> = ({
  isLoading,
  onDenyClick,
  onApproveClick,
}) => {
  const theme = useTheme();

  return (
    <CTARow>
      <TextButton
        dataTestId={ATSJobRequestUpdateTestIds.PRIMARY_DENIAL_BUTTON}
        onClick={onDenyClick}
        sx={{
          marginRight: theme.spacing(1),
          border: `1px solid ${theme.palette.text.primary}`,
        }}
        disabled={isLoading}
        color="black"
      >
        Deny
      </TextButton>
      <PrimaryButton
        dataTestId={ATSJobRequestUpdateTestIds.PRIMARY_APPROVE_BUTTON}
        onClick={onApproveClick}
        disabled={isLoading}
      >
        Approve
      </PrimaryButton>
    </CTARow>
  );
};
