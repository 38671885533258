import { ChangeEventHandler, Component } from 'react';
import axios from 'axios';
import shortid from 'shortid';

import ScorecardEdit from '../components/ScorecardEdit';
import ScorecardEditConfirmation from '../components/ScorecardEdit/ScorecardEditConfirmation';
import AddScorecardQuestionModal from '../components/ScorecardEdit/AddScorecardQuestionModal';
import CopyScorecardModal from 'components/ScorecardEdit/CopyScorecardModal';
import LoadingSpinner from '../components/loadingSpinner';
import auth from '../utils/auth';
import nimbleQuestionsAPI from 'api/nimbleQuestionsAPI';
import customScorecardDetailAPI from 'api/customScorecardDetailAPI';
import customScorecardListAPI from 'api/customScorecardListAPI';

import './scorecardedit.css';
import { scorecardQType } from 'utils/enums';
import { Prompt, withRouter } from 'react-router-dom';
import { Scorecard, ScorecardEditProps, ScorecardEditState, ScorecardQuestion } from './types';
import { ScorecardQuestionType } from 'utils/constants';

class ScorecardEditContainer extends Component<ScorecardEditProps, ScorecardEditState> {
  isSchoolUser: boolean;
  ignoreLastFetch: boolean;

  constructor(props: ScorecardEditProps) {
    super(props);
    this.state = {
      scorecard: {
        title: '',
        questions: [],
        view_permissions: 'me_only',
      },
      loading: false,
      showQuestionModal: false,
      showCopyScorecardModal: false,
      editQuestionType: null,
      questionBank: [],
      errorMessage: '',
      isDirty: false,
      isConfirmationOpen: false,
    };
    this.isSchoolUser = auth.isSchoolUser();
  }

  componentDidMount(): void {
    // if no id in params, the user is creating a new scorecard, so no axios call is needed yet.
    if (this.props.match?.params.id) {
      this.setState({ loading: true });
      axios
        .get(`/api/customscorecard/${this.props.match.params.id}/`)
        .then((r) => {
          if (!this.ignoreLastFetch) {
            const scorecard = r.data;
            this.setState({
              scorecard,
              loading: false,
            });
          }
        })
        .catch((err) => console.error(err));
      this.setState({ loading: false });
    }
    this.fetchNimbleQuestions();
  }

  componentWillUnmount(): void {
    this.ignoreLastFetch = true;
  }

  fetchNimbleQuestions = (): Promise<void> => {
    return nimbleQuestionsAPI
      .getAll()
      .then((questionBank) => {
        if (!this.ignoreLastFetch) {
          this.setState({ questionBank });
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  openNewQuestionModal = (type: ScorecardQuestionType | null): void => {
    if (type !== null) {
      this.setState({ editQuestionType: type }, this.openModal);
    }
  };

  openModal = (): void => {
    this.setState({ showQuestionModal: true });
  };

  updateField = (e: React.ChangeEvent<HTMLInputElement>, id: number): void => {
    const name = e.target.name;
    const scorecard = this.state.scorecard;
    if (e.target.type === 'checkbox') {
      scorecard.questions[id][name] = e.target.checked;
    } else {
      scorecard[name] = e.target.value;
    }
    this.setState({ scorecard, errorMessage: '', isDirty: true });
  };

  setQuestionOrder = (newQuestionsList: ScorecardQuestion[]): void => {
    const scorecard = this.state.scorecard;
    scorecard.questions = newQuestionsList;
    this.setState({ scorecard, isDirty: true });
  };

  deleteQuestion = (index: number): void => {
    const scorecard = this.state.scorecard;
    scorecard.questions.splice(index, 1);
    this.setState({
      scorecard,
      showQuestionModal: false,
      isDirty: true,
    });
  };

  saveQuestion = (question: ScorecardQuestion): void => {
    const scorecard = { ...this.state.scorecard };

    // new questions don't have ids, so find them by their draggable id.
    const idField = question.id ? 'id' : 'draggable_id';

    const index = scorecard.questions.findIndex((item) => item[idField] === question[idField]);
    // insert it into questions array at index, replacing the old question.
    scorecard.questions.splice(index, 1, question);
    this.setState({
      scorecard,
      showQuestionModal: false,
      isDirty: true,
    });
  };

  addQuestion = (question: ScorecardQuestion): void => {
    const scorecard = { ...this.state.scorecard };
    scorecard.questions.push(question);
    this.setState({
      scorecard,
      showQuestionModal: false,
      isDirty: true,
    });
  };

  saveScorecard = (): void => {
    try {
      this.checkForErrors();
    } catch (error) {
      this.setState({ errorMessage: (error as Error).message });
      return;
    }

    this.setState({ loading: true, isDirty: false, isConfirmationOpen: false });

    const scorecard = this.state.scorecard;

    if (scorecard.id) {
      customScorecardDetailAPI
        .updateScorecard(scorecard)
        .then(this.directToSettingsPage)
        .catch((err) => {
          console.error(err);
          const errorMessage =
            err.response?.data[0] ||
            `Oops, there was a problem with the submission.
             Please contact support@hirenimble.com for assistance.`;
          this.setState({
            loading: false,
            errorMessage,
          });
        });
    } else {
      customScorecardListAPI
        .createScorecard(scorecard)
        .then(this.directToSettingsPage)
        .catch((err) => {
          console.error(err);
          this.setState({
            loading: false,
            errorMessage: `Oops, there was a problem with the submission.
            Please contact support@hirenimble.com for assistance.`,
          });
        });
    }
  };

  checkForErrors = (): void => {
    if (!this.state.scorecard.title) {
      throw new Error('Please add a title');
    }

    // Cannot add more than 1 cumulative score question
    // Cannot add more than 1 final recommendation question
    let hasCumulativeScoreItem = false;
    let hasFinalRecommendationItem = false;
    this.state.scorecard.questions.forEach((question) => {
      if (question.question_type === scorecardQType.cumulative_score) {
        if (hasCumulativeScoreItem) {
          throw new Error('You can only add one Cumulative Score item per scorecard');
        } else {
          hasCumulativeScoreItem = true;
        }
      }
      if (question.question_type === scorecardQType.final_recommendation) {
        if (hasFinalRecommendationItem) {
          throw new Error('You can only add one Final Recommendation item per scorecard');
        } else {
          hasFinalRecommendationItem = true;
        }
      }
    });
  };

  directToSettingsPage = (): void => {
    const prefix = this.isSchoolUser ? 'school' : 'district';
    this.props.history.push(`/${prefix}/settings/`);
  };

  deleteScorecard = (): void => {
    this.setState({ loading: true });
    customScorecardDetailAPI
      .deleteScorecard(this.state.scorecard.id)
      .then(this.directToSettingsPage);
  };

  copyScorecard = (scorecard: Scorecard): void => {
    const newScorecard = { ...this.state.scorecard };
    scorecard.questions.forEach((question) => {
      newScorecard.questions.push({
        // copy everything except id (and create a new draggable_id)
        draggable_id: shortid.generate(),
        question_type: question.question_type,
        question_text: question.question_text,
        is_required: question.is_required,
        scale: question.scale,
        mc_options: question.mc_options,
        multi_select: question.multi_select,
        include_rating_questions: question.include_rating_questions,
        include_rubric_questions: question.include_rubric_questions,
        show_total_on_preview: question.show_total_on_preview,
        attachment_directions: question.attachment_directions,
        include_maybe_option: question.include_maybe_option,
        show_answer_on_preview: question.show_answer_on_preview,
        directions: question.directions,
        rubric_columns: question.rubric_columns,
      });
    });
    this.setState({
      scorecard: newScorecard,
      showCopyScorecardModal: false,
    });
  };

  cancelScorecardChanges = (): void => {
    if (this.state.isConfirmationOpen) {
      // user confirmed and we're back here:
      this.setState({ isDirty: false, isConfirmationOpen: false }, () => {
        const urlPrefix = auth.isSchoolUser() ? 'school' : 'district';
        this.props.history.push(`/${urlPrefix}/settings`);
      });
    } else {
      this.setState({
        isConfirmationOpen: true,
      });
    }
  };

  render(): React.ReactNode {
    if (this.state.loading) {
      return <LoadingSpinner />;
    }
    return (
      <div>
        {this.state.scorecard && (
          <ScorecardEdit
            scorecard={this.state.scorecard}
            openNewQuestionModal={this.openNewQuestionModal}
            updateField={
              this.updateField as ChangeEventHandler<HTMLSelectElement | HTMLInputElement>
            }
            setQuestionOrder={this.setQuestionOrder}
            saveScorecard={this.saveScorecard}
            deleteScorecard={this.deleteScorecard}
            cancelChanges={this.cancelScorecardChanges}
            saveQuestion={this.saveQuestion}
            deleteQuestion={this.deleteQuestion}
            questionBank={this.state.questionBank}
            openCopyScorecardModal={() => {
              this.setState({ showCopyScorecardModal: true });
            }}
            errorMessage={this.state.errorMessage}
          />
        )}
        {this.state.showQuestionModal && (
          <AddScorecardQuestionModal
            show={this.state.showQuestionModal}
            onHide={() => {
              this.setState({ showQuestionModal: false });
            }}
            questionType={this.state.editQuestionType}
            addQuestion={this.addQuestion}
            questionBank={this.state.questionBank}
          />
        )}
        {this.state.showCopyScorecardModal && (
          <CopyScorecardModal
            isOpen={this.state.showCopyScorecardModal}
            onClose={() => {
              this.setState({ showCopyScorecardModal: false });
            }}
            copyScorecard={this.copyScorecard}
          />
        )}
        <ScorecardEditConfirmation
          isOpen={this.state.isConfirmationOpen}
          onClose={() => {
            this.setState({ isConfirmationOpen: false });
          }}
          onDiscard={() => {
            this.cancelScorecardChanges();
          }}
          onSave={() => {
            this.saveScorecard();
          }}
        />
        <Prompt
          when={this.state.isDirty}
          message="Looks like you have unsaved changes to this scorecard form. Are you sure you want to leave?"
        />
      </div>
    );
  }
}

export default withRouter(ScorecardEditContainer);
