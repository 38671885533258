import Certification from 'components/Profile/Basics/Certification';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { QuickProfileUser } from 'types';
import CloseIcon from '../../../assets/icon-close-x.svg';
import OverviewSectionQuickProfile from '../QuickProfileCandidate/OverviewSectionQuickProfile';
import Attachments from 'components/Profile/Attachments';
import QuickProfileApplicationSection from 'components/Profile/ProfileApplicationSection/QuickProfileApplicationSection';
import MarketPlaceFitBadge from './components/MarketPlaceFitBadge';
import MarketPlaceFitButtons from './components/MarketPlaceFitButtons';
import talentMarketplaceAPI from 'api/talentMarketplaceAPI';
import QuickProfileAPI from 'api/quickProfileAPI';
import { notify } from 'react-notify-toast';
import { myColorWarning } from 'utils/message';
import LoadingSpinner from 'components/loadingSpinner';
import { getATSCredentials } from 'utils/candidateUtils';
import { MarketplaceFitStatus } from 'utils/constants';

interface TalentQuickViewProps {
  user: Omit<QuickProfileUser, 'application'>;
  toggleVisibility: () => void;
  show: boolean;
}

const TalentQuickView = ({
  user,
  toggleVisibility,
  show,
}: TalentQuickViewProps): React.ReactElement => {
  const [fullUser, setFullUser] = useState<QuickProfileUser>({ ...user });
  const [showSpinner, setShowSpinner] = useState(true);

  const stopSpinner = () => {
    setShowSpinner(false);
  };

  useEffect(() => {
    document.body.classList.add('quick-profile-open');
    if (fullUser?.user.id) {
      QuickProfileAPI.fetchFullUserNoApplications(fullUser.user.id).then((newFetchedUser) => {
        setFullUser(newFetchedUser);
        stopSpinner();
      });
    }

    return () => {
      document.body.classList.remove('quick-profile-open');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMarketplaceStatusUpdate = (updatedFitStatus: MarketplaceFitStatus) => {
    talentMarketplaceAPI
      .updateCandidateDistrictInformation({
        user: fullUser.id,
        marketplace_fit_status: updatedFitStatus,
      })
      .then(() => {
        setFullUser({
          ...fullUser,
          candidate_district_information: {
            ...fullUser.candidate_district_information,
            marketplace_fit_status: updatedFitStatus,
          },
        });
        //TODO - nice to have: Splice user out of candidate list.
      })
      .catch((err) => {
        notify.show(
          err?.response?.data?.error || 'Error updating talent status',
          'custom',
          2000,
          myColorWarning
        );
      });
  };

  const atsCredentials = getATSCredentials(fullUser.credentials);

  return (
    <>
      <div>
        <div className="quick-profile-fade" hidden={!show} onClick={toggleVisibility} />
        {show && (
          <div className="quick-profile-container">
            {showSpinner ? (
              <LoadingSpinner />
            ) : (
              <div className="flex-1 quick-profile-inside">
                <CloseButton onClick={toggleVisibility}>
                  <img alt="icon-clock" src={CloseIcon} style={{ paddingRight: '6px' }} />
                </CloseButton>
                <div className="default-card" style={{ padding: '39px 0 0 2px' }}>
                  <ProfileSection>
                    <div
                      className="QuickProfile__header"
                      style={{ paddingLeft: '8px', width: '100%' }}
                    >
                      <div className="flex">
                        <div className="QuickProfile__header-name">
                          <div style={{ display: 'flex', textAlign: 'center' }}>
                            <Header>{fullUser?.name || ''}</Header>
                            <MarketPlaceFitBadge
                              status={
                                fullUser?.candidate_district_information?.marketplace_fit_status
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <MarketPlaceButtonContainer>
                        <MarketPlaceFitButtons
                          selectedButton={
                            fullUser?.candidate_district_information?.marketplace_fit_status
                          }
                          onMarketplaceStatusUpdate={onMarketplaceStatusUpdate}
                        />
                      </MarketPlaceButtonContainer>
                    </div>
                  </ProfileSection>
                  <ProfileInformation>
                    <Section>
                      <OverviewSectionQuickProfile
                        fullUser={fullUser}
                        application={null}
                        isTalentMarketplace={true}
                      />
                    </Section>
                    {atsCredentials &&
                      (atsCredentials.length > 0 || fullUser.profile.has_no_credentials) && (
                        <Section>
                          <Certification
                            subjects={atsCredentials}
                            hasNoCredentials={fullUser.profile.has_no_credentials}
                            isTalentMarketplace
                          />
                        </Section>
                      )}
                    {fullUser.profile?.resume && (
                      <Section>
                        <Attachments
                          fullUser={fullUser}
                          districtApplicationBeingQuickviewed={null}
                          showDownloadIcon={false}
                          isTalentMarketplace
                        />
                      </Section>
                    )}
                    <Section>
                      <QuickProfileApplicationSection
                        user={fullUser}
                        districtApplication={null}
                        isTalentMarketplace
                      />
                    </Section>
                    <Section style={{ marginBottom: '32px' }} />
                  </ProfileInformation>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const ProfileInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const Section = styled.div`
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

export const CloseButton = styled.span`
  position: absolute;
  top: 22px;
  right: 28px;
  font-size: 28px
  font-weight: bold;
  cursor: pointer;
  color: #A3A1B3 !important;

  @media screen and (max-width: 768px) {
    top: 10px;
    right: 8px;
    font-size: 24px;
  }
`;

const ProfileSection = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 2rem;
  margin: 0 -1rem;
`;

const Header = styled.p`
  font-size: 25px;
  font-weight: 600;
  line-height: 24px;
  color: #444444;
  margin-right: 8px;
`;

const MarketPlaceButtonContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export default TalentQuickView;
