import { FullUser } from 'types';

export const deleteNoteFromState = (
  noteId: number,
  user: FullUser,
  handleUpdateUser: (updatedUser: FullUser) => void
) => {
  const fullUser = { ...user };
  const deleteIndex = fullUser.internal_candidate_notes.findIndex((note) => note.id === noteId);

  if (deleteIndex !== -1) {
    fullUser.internal_candidate_notes.splice(deleteIndex, 1);
    handleUpdateUser(fullUser);
  }
};
