export enum JobRequestFormKind {
  denial = 'denial',
  approval = 'approval',
}

export const JobRequestAction = {
  return: 'return',
  archive: 'archive',
  create: 'create',
  merge: 'merge',
} as const;

export type JobRequestFormKindType = (typeof JobRequestFormKind)[keyof typeof JobRequestFormKind];
