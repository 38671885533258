import { Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import AuthenticatedRoute from '../components/AuthenticatedRoute';
import CandidatesListContainer from '../containers/candidateslist';
import InterviewsContainer from './interviews';
import ItemsList from 'features/ItemsList';
import ProfileContainer from '../containers/profile';
import ProfileContainerSchoolApplication from './profile_schoolapplication';
import RequisitionRole from '../containers/requisition_role';
import RequisitionRolesListContainer from '../containers/requisition_roleslist';
import SchoolAccount from '../components/SchoolAccount';
import SchoolJobCreateContainer from './schooljobcreate';
import SchoolJobEditContainer from './schooljobedit';
import useCurrentUserPermissions from '../hooks/useCurrentUserPermissions';
import UserSettingsContainer from '../containers/usersettings';
import UserAuthorized from '../components/UserAuthorized';
import { schoolUserTypes } from '../utils/userType';
import DistrictHome from '../containers/districthome';
import JobViewWrapper from './JobviewWrapper';

const School: React.FC = () => {
  const { userType } = useCurrentUserPermissions();
  const routeMatch = useRouteMatch('/school');
  const location = useLocation();

  if (!routeMatch) return null;

  if (userType === 'district_admin' || userType === 'district_user' || userType === 'super_admin') {
    const currentRoute = location.pathname;
    const newRoute = currentRoute.replace('/school', '/district');
    return <Redirect to={newRoute} />;
  }

  return (
    <UserAuthorized allow={schoolUserTypes}>
      <Switch>
        <AuthenticatedRoute path="/school/home">
          <DistrictHome />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/school/account">
          <SchoolAccount />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/school/candidates">
          <CandidatesListContainer />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/school/requisition-roles">
          <RequisitionRolesListContainer />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/school/requisition-view/:id">
          <RequisitionRole />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/school/jobslist">
          <ItemsList variant="jobs" />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/school/jobedit/:id">
          <SchoolJobEditContainer />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/school/jobcreate">
          <SchoolJobCreateContainer />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/school/profile/:id">
          <ProfileContainer />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/school/school-profile/:id">
          <ProfileContainerSchoolApplication />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/school/jobview/:id">
          <JobViewWrapper />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/school/settings">
          <UserSettingsContainer />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/school/interviews">
          <InterviewsContainer />
        </AuthenticatedRoute>
      </Switch>
    </UserAuthorized>
  );
};

export default School;
