import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Redirect, useLocation } from 'react-router';

import { JobViewSchoolRole } from 'types';
import auth from 'utils/auth';
import { LandingForm } from './components/LandingForm';
import { SuccessPage } from './components/SuccessPage';
import { useCreateConnectATSApplicationMutation } from './mutations';
import { useQueryFetchCandidateForConnectATSIntegration } from './queries';
import { FormState } from './types';

export const AdminLandingForm = React.forwardRef(() => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const candidateId = Number(queryParams.get('candidate_id'));
  const { data: candidateInfo } = useQueryFetchCandidateForConnectATSIntegration(candidateId);
  const { data, mutate, isSuccess } = useCreateConnectATSApplicationMutation({});

  const handleSubmit = (event, title: string, schools: JobViewSchoolRole[]) => {
    event.preventDefault();
    const submitHandler = methods.handleSubmit((formState) => {
      const formStateToSubmit = {
        role_id: formState.roleId,
        candidate_id: candidateId,
        internal_candidate_note_content: formState.internalNote,
        title,
        schools,
      };

      return mutate(formStateToSubmit);
    });

    submitHandler(event);
  };

  const methods = useForm<FormState>({
    defaultValues: {
      roleId: null,
      internalNote: '',
    },
  });

  if (!auth.hasConnectIntegrationModule()) {
    window.pendo?.track('invalid_connect_import_no_module_access', {
      eventTime: new Date().toISOString(),
    });
    const redirectPath = auth.isDistrictUser() ? '/district/home' : '/school/home';
    return <Redirect to={redirectPath} />;
  }

  return (
    <>
      {!isSuccess && (
        <FormProvider {...methods}>
          <LandingForm
            candidateId={candidateId}
            candidateEmail={candidateInfo?.email}
            candidateName={candidateInfo?.name}
            onSubmit={handleSubmit}
          />
        </FormProvider>
      )}
      {isSuccess && data?.title && (
        <SuccessPage
          title={data.title}
          candidateEmail={candidateInfo?.email}
          candidateName={candidateInfo?.name}
          schools={data.schools}
          internalNote={data.internal_candidate_note_content}
        />
      )}
    </>
  );
});

AdminLandingForm.displayName = 'AdminLandingForm';
