import { USStates } from 'utils/enums';

export const STATE_DROPDOWN_OPTIONS = USStates().map((state) => {
  return {
    label: state.label,
    value: state.id,
  };
});

export const CONNECT_JOBBOARD_STATE_IDS = {
  MISSOURI: 25,
  TENNESSEE: 42,
};

export const MAX_FILE_SIZE_IN_BYTES = 5242880; // equivalent to 5 megabytes

export const MAX_FILE_NAME_CHARACTER_LENGTH = 215;

export const CONNECT_PROFILE_STATE_PREFERENCE_OPTIONS = [
  { label: 'Alabama', value: 1, isActive: false },
  { label: 'Alaska', value: 2, isActive: false },
  { label: 'Arizona', value: 3, isActive: false },
  { label: 'Arkansas', value: 4, isActive: false },
  { label: 'California', value: 5, isActive: false },
  { label: 'Colorado', value: 6, isActive: false },
  { label: 'Connecticut', value: 7, isActive: false },
  { label: 'Delaware', value: 8, isActive: false },
  { label: 'Florida', value: 9, isActive: false },
  { label: 'Georgia', value: 10, isActive: false },
  { label: 'Hawaii', value: 11, isActive: false },
  { label: 'Idaho', value: 12, isActive: false },
  { label: 'Illinois', value: 13, isActive: false },
  { label: 'Indiana', value: 14, isActive: false },
  { label: 'Iowa', value: 15, isActive: false },
  { label: 'Kansas', value: 16, isActive: false },
  { label: 'Kentucky', value: 17, isActive: false },
  { label: 'Louisiana', value: 18, isActive: false },
  { label: 'Maine', value: 19, isActive: false },
  { label: 'Maryland', value: 20, isActive: false },
  { label: 'Massachusetts', value: 21, isActive: false },
  { label: 'Michigan', value: 22, isActive: false },
  { label: 'Minnesota', value: 23, isActive: false },
  { label: 'Mississippi', value: 24, isActive: false },
  { label: 'Missouri', value: 25, isActive: true },
  { label: 'Montana', value: 26, isActive: false },
  { label: 'Nebraska', value: 27, isActive: false },
  { label: 'Nevada', value: 28, isActive: false },
  { label: 'New Hampshire', value: 29, isActive: false },
  { label: 'New Jersey', value: 30, isActive: false },
  { label: 'New Mexico', value: 31, isActive: false },
  { label: 'New York', value: 32, isActive: false },
  { label: 'North Carolina', value: 33, isActive: false },
  { label: 'North Dakota', value: 34, isActive: false },
  { label: 'Ohio', value: 35, isActive: false },
  { label: 'Oklahoma', value: 36, isActive: false },
  { label: 'Oregon', value: 37, isActive: false },
  { label: 'Pennsylvania', value: 38, isActive: false },
  { label: 'Rhode Island', value: 39, isActive: false },
  { label: 'South Carolina', value: 40, isActive: false },
  { label: 'South Dakota', value: 41, isActive: false },
  { label: 'Tennessee', value: 42, isActive: true },
  { label: 'Texas', value: 43, isActive: false },
  { label: 'Utah', value: 44, isActive: false },
  { label: 'Vermont', value: 45, isActive: false },
  { label: 'Virginia', value: 46, isActive: false },
  { label: 'Washington', value: 47, isActive: false },
  { label: 'West Virginia', value: 48, isActive: false },
  { label: 'Wisconsin', value: 49, isActive: false },
  { label: 'Wyoming', value: 50, isActive: false },
  { label: 'Guam', value: 51, isActive: false },
  { label: 'Puerto Rico', value: 52, isActive: false },
  { label: 'Virgin Islands', value: 53, isActive: false },
  { label: 'District of Columbia', value: 54, isActive: false },
];
