import { Typography } from '@mui/material';

import { ApprovalFormProps, FormFields } from '../types';
import { CloseButton } from 'sharedComponents/Buttons/CloseButton';
import { CloseButtonAndHelpText, Form, FormContainer, FormOptions, TitleContainer } from './styles';
import { ToggleButtons } from 'sharedComponents/Buttons/ToggleButtons';
import { approvalOptions } from '../data';
import { ATSJobRequestUpdateTestIds } from 'data-testids/ATS';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { MergeSubForm } from './MergeSubform';
import { CreateSubForm } from './CreateSubForm';
import { JobviewJob } from 'types';
import { JobRequestAction } from '../enum';

export const ApprovalForm: React.FC<ApprovalFormProps> = ({
  action,
  currentlySelectedToggle,
  hasPendingMutation,
  onMergeSubmit,
  onInnerFormClose,
  onToggle,
  onCreateSubmit,
}) => {
  const mergeMethods = useForm({
    defaultValues: {
      jobToMergeInto: null,
    },
  });

  const {
    formState: { errors: mergeErrors },
    handleSubmit: handleMergeSubmit,
  } = mergeMethods;

  const createMethods = useForm({
    defaultValues: {
      title: '',
      template: null,
    },
  });

  const {
    formState: { errors: createErrors },
    handleSubmit: handleCreateSubmit,
  } = createMethods;

  const handleMergeFormSubmit = (
    data: { jobToMergeInto: JobviewJob },
    event: { preventDefault: () => void }
  ) => {
    event.preventDefault();
    const job = data.jobToMergeInto;
    onMergeSubmit(job);
  };

  const handleCreateFormSubmit = (
    data: { title: string; template: { id: number } },
    event: { preventDefault: () => void }
  ) => {
    event.preventDefault();
    const title = data?.title;
    const templateId = data?.template?.id || undefined;

    onCreateSubmit(title, templateId);
  };

  const hasComboboxError = Boolean(mergeErrors?.jobToMergeInto?.message);
  const hasTitleError = Boolean(createErrors?.title?.message);
  const ctaShouldBeDisabled = hasComboboxError || hasTitleError || hasPendingMutation;

  const methodsToUse =
    action === JobRequestAction.create
      ? (createMethods as UseFormReturn<FormFields>)
      : (mergeMethods as UseFormReturn<FormFields>);

  const onSubmitToUse =
    action === JobRequestAction.create
      ? handleCreateSubmit(handleCreateFormSubmit)
      : handleMergeSubmit(handleMergeFormSubmit);

  return (
    <FormProvider {...methodsToUse}>
      <Form onSubmit={onSubmitToUse}>
        <FormContainer>
          <FormOptions>
            <CloseButtonAndHelpText>
              <CloseButton
                dataTestId={ATSJobRequestUpdateTestIds.INNER_FORM_CLOSE_BUTTON}
                onClick={onInnerFormClose}
              />
              <TitleContainer>
                <Typography data-testid={ATSJobRequestUpdateTestIds.APPROVAL_PROMPT_TEXT}>
                  What would you like to do with this request?
                </Typography>
              </TitleContainer>
            </CloseButtonAndHelpText>

            <ToggleButtons
              currentlySelected={currentlySelectedToggle}
              onToggle={onToggle}
              options={approvalOptions}
              dataTestId={ATSJobRequestUpdateTestIds.APPROVAL_TOGGLE_BUTTONS}
            />
            {action === JobRequestAction.create ? (
              <CreateSubForm hasTitleError={hasTitleError} />
            ) : (
              <MergeSubForm hasComboboxError={hasComboboxError || hasTitleError} />
            )}
          </FormOptions>
          <PrimaryButton
            dataTestId={ATSJobRequestUpdateTestIds.SECONDARY_APPROVE_BUTTON}
            sx={{ width: '40%' }}
            type="submit"
            disabled={ctaShouldBeDisabled}
          >
            Approve Request
          </PrimaryButton>
        </FormContainer>
      </Form>
    </FormProvider>
  );
};
