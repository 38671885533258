import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import connectCircles from './../../../../../assets/illustration-empty-search.svg';

export const NoResultsFoundCard: React.FC<React.ReactNode> = () => {
  return (
    <NoResultsFoundContainer>
      <>
        <div style={{ height: '48px' }} />
        <StyledTitle>
          No results found for your search criteria. Try expanding your search radius or adjusting
          your filters.
        </StyledTitle>
        <StyledSubtitle>
          Please <StyledLink href="mailto:support@hirenimble.com">contact us</StyledLink> if you
          need help finding teaching jobs in your area.
        </StyledSubtitle>
      </>
    </NoResultsFoundContainer>
  );
};

const NoResultsFoundContainer = styled(Box)(() => ({
  display: 'flex',
  padding: '24px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '24px',
  justifyContent: 'center',
  borderRadius: '12px',
  border: '1px solid  #EAECF0',
  backgroundImage: `url(${connectCircles})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.dark,
  textAlign: 'center',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '28px',
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.light,
  textAlign: 'center',
  fontSize: theme.typography.body2.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '28px',
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.medium,
  '&:hover': {
    color: theme.palette.primary.medium,
    textDecoration: 'underline',
  },
}));
