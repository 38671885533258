import { Autocomplete, TextField, InputAdornment } from '@mui/material';
import { SearchableSelectProps } from './types';
import SearchIcon from '@mui/icons-material/Search';

export const SearchableSelect: React.FC<SearchableSelectProps> = ({
  options = [],
  placeholder,
  handleChange,
  disabled = false,
  name,
  value,
  error = false,
  helperText,
  sx,
  isSearchbar = false,
}) => {
  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      options={options}
      onChange={handleChange}
      value={value}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      sx={sx}
      renderOption={(props, option) => (
        <li {...props} key={option.value}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          name={name}
          sx={sx}
          error={error}
          helperText={error && helperText}
          InputProps={{
            ...params.InputProps,
            sx: { borderRadius: '12px' },
            ...(isSearchbar && {
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }),
          }}
        />
      )}
      disableClearable
    />
  );
};
