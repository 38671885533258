import { Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import ATSRoute from './ATSRoute';
import AuthenticatedRoute from '../components/AuthenticatedRoute';
import CandidatesListContainer from '../containers/candidateslist';
import DistrictAccount from '../containers/districtaccount';
import DistrictHome from '../containers/districthome';
import { districtUserTypes } from '../utils/userType';
import InterviewsContainer from './interviews';
import ItemsList from 'features/ItemsList';
import JobCreateContainer from '../containers/jobcreate';
import JobEditContainer from '../containers/jobedit';
import JobPreviewContainer from '../containers/jobpreview';
import ProfileContainer from '../containers/profile';
import RequisitionRole from '../containers/requisition_role';
import RequisitionRolesListContainer from '../containers/requisition_roleslist';
import useCurrentUserPermissions from '../hooks/useCurrentUserPermissions';
import UserSettingsContainer from '../containers/usersettings';
import UserAuthorized from '../components/UserAuthorized';
import { NetworkRequestStatusProvider } from 'context/networkRequestStatusContext';
import JobViewWrapper from './JobviewWrapper';

const District: React.FC = () => {
  const { userType } = useCurrentUserPermissions();
  const routeMatch = useRouteMatch('/district');
  const { pathname } = useLocation();

  if (!routeMatch) return null;

  if (userType === 'school_admin' || userType === 'school_user') {
    const newRoute = pathname.replace('/district', '/school');
    return <Redirect to={newRoute} />;
  }

  return (
    <UserAuthorized allow={districtUserTypes}>
      <Switch>
        {/* { ats routes } */}
        <ATSRoute path="/district/home">
          <DistrictHome />
        </ATSRoute>
        <ATSRoute path="/district/candidates">
          <CandidatesListContainer />
        </ATSRoute>
        <ATSRoute path="/district/settings">
          <UserSettingsContainer />
        </ATSRoute>
        <ATSRoute path="/district/requisition-roles">
          <RequisitionRolesListContainer />
        </ATSRoute>
        <ATSRoute path="/district/requisition-view/:id">
          <RequisitionRole />
        </ATSRoute>
        <ATSRoute path="/district/profile/:id">
          <NetworkRequestStatusProvider>
            <ProfileContainer />
          </NetworkRequestStatusProvider>
        </ATSRoute>
        <ATSRoute path="/district/interviews">
          <InterviewsContainer />
        </ATSRoute>
        {/* Role template routes */}
        <AuthenticatedRoute path="/district/templates">
          <ItemsList variant="templates" />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/district/template-preview/:id">
          <JobPreviewContainer />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/district/template-edit/:id">
          <JobEditContainer isTemplate={true} />
        </AuthenticatedRoute>
        {/* all other routes */}
        <AuthenticatedRoute path="/district/account">
          <DistrictAccount />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/district/jobslist">
          <ItemsList variant="jobs" />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/district/jobview/:id">
          <NetworkRequestStatusProvider>
            <JobViewWrapper />
          </NetworkRequestStatusProvider>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/district/jobpreview/:id">
          <JobPreviewContainer />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/district/jobedit/:id">
          <NetworkRequestStatusProvider>
            <JobEditContainer />
          </NetworkRequestStatusProvider>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/district/jobcreate">
          <JobCreateContainer />
        </AuthenticatedRoute>
      </Switch>
    </UserAuthorized>
  );
};

export default District;
