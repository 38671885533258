import { ToggleButtonAlignment } from 'sharedComponents/Buttons/ToggleButtons';

export const approvalOptions = [
  { title: 'Create new job', id: ToggleButtonAlignment.LEFT_TOGGLE_ACTIVE },
  { title: 'Merge job', id: ToggleButtonAlignment.RIGHT_TOGGLE_ACTIVE },
];

export const denialOptions = [
  {
    title: 'Deny & Archive',
    requestDenialAction: 'archive',
    id: ToggleButtonAlignment.LEFT_TOGGLE_ACTIVE,
  },
  {
    title: 'Return with note',
    requestDenialAction: 'return',
    id: ToggleButtonAlignment.RIGHT_TOGGLE_ACTIVE,
  },
];
