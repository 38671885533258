import { Box } from '@mui/material';
import React from 'react';
import { AnnouncementBannerProps } from './types';
import { SxProps, Theme } from '@mui/material/styles';

export const AnnouncementBanner: React.FC<AnnouncementBannerProps> = ({
  body,
  sx,
  dataTestId,
  backgroundColor = 'primary',
  startIcon,
  endIcon,
  iconColor = 'inherit',
  iconStyle,
}) => {
  const bannerStyles = {
    backgroundColor: (theme) => theme.palette[backgroundColor]?.main || theme.palette.primary.main,
    color: (theme) => theme.palette.white?.main,
    ...sx,
  };

  return (
    <Box data-testid={dataTestId} sx={bannerStyles} color={backgroundColor}>
      {startIcon && (
        <Box
          component="span"
          sx={{
            ...iconStyles(iconColor, iconStyle),
          }}
        >
          {startIcon}
        </Box>
      )}
      {body}
      {endIcon && (
        <Box
          component="span"
          sx={{
            ...iconStyles(iconColor, iconStyle),
          }}
        >
          {endIcon}
        </Box>
      )}
    </Box>
  );
};

export const iconStyles = (iconColor: string, iconStyle: SxProps<Theme>): SxProps<Theme> => ({
  color: iconColor,
  display: 'flex',
  alignItems: 'center',
  ...iconStyle,
});
