export * from './utils';

import { nimbleTheme } from 'theme';
import { Action, JobDiscoveryInitialState, School, Vacancy } from 'types/connectTypes';
import { ActionTypes, ConnectFilterName } from 'features/Connect/utils/connectEnums';
import { ConnectBadgeLabels } from 'features/Connect/utils/connectEnums';
import { SecondarySchoolTagsBadge } from 'features/Connect/components/InfoBadges/SecondarySchoolTagsBadge';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';

export const Inter = nimbleTheme.typography.fontFamily['Inter'];
export const Aeonik = nimbleTheme.typography.fontFamily['Aeonik TRIAL'];
export const OpenSans = nimbleTheme.typography.fontFamily['Open Sans'];

const SCHOOL_TAG_LIMIT = 2;

export const initialState: JobDiscoveryInitialState = {
  schools: [],
  state_abbreviation: 'MO',
  page: 1,
  query: '',
  subcategories: [],
  district_nces_ids: [],
  school_nces_ids: [],
  grades: [],
  location: {
    distance_radius_miles: '',
    location_latitude: '',
    location_longitude: '',
  },
  activeFilters: {
    location: false,
    distance_radius_miles: false,
    grades: false,
    subcategories: false,
    school_nces_ids: false,
    district_nces_ids: false,
  },
  isLoading: true,
  isLoadingInitialFilterResults: false,
  useDebounce: false,
  expressInterestModalIsOpen: false,
  expressInterestExitModalIsOpen: false,
  selectedSchool: null,
  moreInfoSliderIsOpen: false,
  selectedVacancyId: '0',
  readyToConnectModalIsOpen: false,
  contactedSchoolIds: {},
  savedSchoolIds: {},
  isUserAuthenticated: false,
};

export const reducer = (
  state: JobDiscoveryInitialState,
  action: Action
): JobDiscoveryInitialState => {
  switch (action.type) {
    case ActionTypes.SET_PAGE:
      return { ...state, useDebounce: false, page: action.payload };
    case ActionTypes.SET_QUERY:
      return { ...state, query: action.payload, useDebounce: false, isLoading: true, page: 1 };
    case ActionTypes.SET_FILTER:
      return {
        ...state,
        [action.filterName]: action.payload,
        useDebounce: action.filterName === ConnectFilterName.LOCATION ? false : true,
        isLoading: true,
        page: 1,
      };
    case ActionTypes.SET_FILTER_IS_ACTIVE:
      return {
        ...state,
        activeFilters: { ...state.activeFilters, [action.filterName]: action.payload },
        page: 1,
      };
    case ActionTypes.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case ActionTypes.SET_USE_DEBOUNCE:
      return { ...state, useDebounce: action.payload };
    case ActionTypes.SET_EXPRESS_INTEREST_MODAL_IS_OPEN:
      return { ...state, expressInterestModalIsOpen: action.payload };
    case ActionTypes.SET_EXPRESS_INTEREST_EXIT_MODAL_IS_OPEN:
      return { ...state, expressInterestExitModalIsOpen: action.payload };
    case ActionTypes.SET_MORE_INFO_SLIDER_IS_OPEN:
      return { ...state, moreInfoSliderIsOpen: action.payload };
    case ActionTypes.SET_SELECTED_SCHOOL:
      return { ...state, selectedSchool: action.payload };
    case ActionTypes.SET_SELECTED_VACANCY_ID:
      return { ...state, selectedVacancyId: action.payload };
    case ActionTypes.SET_SCHOOLS:
      return { ...state, schools: action.payload };
    case ActionTypes.SET_READY_TO_CONNECT_MODAL_IS_OPEN:
      return { ...state, readyToConnectModalIsOpen: action.payload };
    case ActionTypes.SET_CONTACTED_SCHOOL_IDS:
      return {
        ...state,
        contactedSchoolIds: { ...state.contactedSchoolIds, [action.payload]: true },
      };
    case ActionTypes.TOGGLE_SAVED_SCHOOL_IDS: {
      const schoolId = action.payload;
      const newSavedSchoolIds = { ...state.savedSchoolIds };
      if (newSavedSchoolIds[schoolId]) {
        delete newSavedSchoolIds[schoolId];
      } else {
        newSavedSchoolIds[schoolId] = true;
      }
      return {
        ...state,
        savedSchoolIds: newSavedSchoolIds,
      };
    }
    case ActionTypes.SET_IS_LOADING_INITIAL_FILTER_RESULTS:
      return { ...state, isLoadingInitialFilterResults: action.payload };
    case ActionTypes.SET_IS_USER_AUTHENTICATED:
      return { ...state, isUserAuthenticated: action.payload };
    default:
      return state;
  }
};

export const formatDistance = (distance: string | number): string => {
  distance = Number(distance);

  if (distance < 1) {
    return distance.toFixed(2);
  } else {
    return distance.toFixed(1);
  }
};

export const getConnectUrlPath = (jobboardState) => {
  if (jobboardState && CONNECT_JOBBOARD_STATES[jobboardState]?.urlParam) {
    return `/connect/${CONNECT_JOBBOARD_STATES[jobboardState].urlParam}`;
  }
  return '/connect';
};

export function getGradeRange(gradeLevels: string[]): string {
  const numericGrades: number[] = gradeLevels.map((grade) => {
    if (grade === 'PK') {
      return 0;
    } else if (grade === 'KG') {
      return 13;
    } else {
      return Number(grade);
    }
  });

  // return string if school only has single grade
  if (numericGrades.length === 1) {
    if (numericGrades[0] === 0) {
      return 'Pre-K';
    } else if (numericGrades[0] === 13) {
      return 'Kindergarten';
    } else return `${numericGrades[0]}`;
  }

  let minGrade: string;

  if (numericGrades.includes(0)) {
    minGrade = 'Pre-K';
  } else if (numericGrades.includes(13)) {
    minGrade = 'Kindergarten';
  } else {
    minGrade = Math.min(...numericGrades).toString();
  }

  const nonPreKGrades = numericGrades.filter((grade) => grade !== 0);

  let maxGrade: string = Math.max(
    ...numericGrades.filter((grade) => grade !== 0 && grade !== 13)
  ).toString();

  if (nonPreKGrades.length === 1 && nonPreKGrades[0] === 13) {
    maxGrade = 'Kindergarten';
  }

  return `${minGrade} - ${maxGrade}`;
}

export const getSecondarySchoolTags = (school: School) => {
  const allTags = Object.keys(ConnectBadgeLabels).reduce((validTags, tag) => {
    const lowerCaseTag = tag.toLowerCase();
    if (school[`has_${lowerCaseTag}`]) {
      validTags.push(tag);
    }
    return validTags;
  }, []);
  return allTags;
};

export const getStateLabel = (jobboardState: string) => {
  return jobboardState
    ? CONNECT_JOBBOARD_STATES[jobboardState]?.label
    : CONNECT_JOBBOARD_STATES.MISSOURI.label;
};

export const getStateAbbreviation = (jobboardState: string) => {
  return jobboardState
    ? CONNECT_JOBBOARD_STATES[jobboardState]?.stateCode
    : CONNECT_JOBBOARD_STATES.MISSOURI.stateCode;
};

export const getStateOrganizationName = (jobboardState: string) => {
  return jobboardState ? CONNECT_JOBBOARD_STATES[jobboardState]?.organizationName : '';
};

const shouldRenderSecondaryTags = (school: School) => {
  return !school?.principal?.on_connect || !school?.is_recommended;
};

export const renderSecondarySchoolTags = (school: School) => {
  if (!shouldRenderSecondaryTags(school)) {
    return null;
  }
  const tags = getSecondarySchoolTags(school).slice(0, SCHOOL_TAG_LIMIT);
  return tags.map((tag) => (
    <SecondarySchoolTagsBadge key={tag} tagLabel={tag as keyof typeof ConnectBadgeLabels} />
  ));
};

export function sortVacancies(vacancyArray: Vacancy[], vacancyId: string): Vacancy[] {
  const index = vacancyArray.findIndex((obj) => obj.uuid === vacancyId);
  if (index !== -1) {
    const objToMove = vacancyArray[index];
    return [objToMove, ...vacancyArray.slice(0, index), ...vacancyArray.slice(index + 1)];
  }
  return vacancyArray;
}
