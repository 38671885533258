import { Stack, styled, Typography, SxProps, Theme } from '@mui/material';
import { useStateParam } from 'features/Connect/features/ConnectStateCodeContextProvider';
import {
  getStateLabel,
  getStateOrganizationName,
} from 'features/Connect/features/JobDiscovery/utils';

interface StateJobBoardTitleProps {
  sx?: SxProps<Theme>;
  titleTextSize?: string;
  subtitleTextSize?: string;
}

export const StateJobBoardTitle: React.FC<StateJobBoardTitleProps> = ({
  sx,
  titleTextSize,
  subtitleTextSize,
}) => {
  const jobboardState = useStateParam();
  const stateLabel = getStateLabel(jobboardState) || 'Your State';
  const stateOrganizationName = getStateOrganizationName(jobboardState);

  return (
    <Stack direction="column" alignSelf="center" sx={sx}>
      <JobSearchTitle titleTextSize={titleTextSize}>
        {`${stateLabel} Educator Job Search Tool`}
      </JobSearchTitle>
      <JobSearchSubTitle subtitleTextSize={subtitleTextSize}>
        {`Brought to you by the ${stateOrganizationName}`}
      </JobSearchSubTitle>
    </Stack>
  );
};

const JobSearchTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'titleTextSize',
})<{ titleTextSize?: string }>(({ theme, titleTextSize }) => ({
  fontWeight: theme.typography.fontWeightMediumBold,
  fontSize: titleTextSize || theme.typography.heading7.fontSize,
  color: theme.palette.text.secondary,
}));

const JobSearchSubTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'subtitleTextSize',
})<{ subtitleTextSize?: string }>(({ theme, subtitleTextSize }) => ({
  fontWeight: theme.typography.fontWeightLight,
  fontSize: subtitleTextSize || theme.typography.body2.fontSize,
  color: theme.palette.text.secondary,
}));
