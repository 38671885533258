import { QuickProfileUser } from 'types';

export const getEmailAndPhoneText = (email, phone) => {
  if (email && phone) {
    return `${email} | ${phone}`;
  } else if (email && !phone) {
    return email;
  } else if (!email && phone) {
    return phone;
  }
};

export const deleteAttachmentFromState = (
  attachmentId: number,
  user: QuickProfileUser,
  handleUpdateUser: (updatedUser: QuickProfileUser) => void
) => {
  const fullUser = { ...user };
  const deleteIndex = fullUser.application.applicationattachment_set.findIndex(
    (attachment) => attachment.id === attachmentId
  );

  if (deleteIndex !== -1) {
    const originalApplications = [...fullUser.application.applicationattachment_set];
    originalApplications.splice(deleteIndex, 1);
    handleUpdateUser({
      ...fullUser,
      application: {
        ...fullUser.application,
        applicationattachment_set: originalApplications,
      },
    });
  }
};
