import CloseIcon from '@mui/icons-material/Close';
import { IconButton, SxProps } from '@mui/material';

export interface CloseButtonProps {
  dataTestId?: string;
  disabled?: boolean;
  onClick: () => void;
  sx?: SxProps;
}

export const CloseButton: React.FC<CloseButtonProps> = ({ dataTestId, disabled, onClick, sx }) => {
  return (
    <IconButton disabled={disabled} data-testid={dataTestId} onClick={onClick} sx={sx}>
      <CloseIcon />
    </IconButton>
  );
};
