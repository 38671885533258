import { ButtonProps } from './types';
import { StyledSecondaryButton } from './styles';
import { CircularProgress, Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

export const SecondaryButton: React.FC<ButtonProps> = ({
  children,
  disabled,
  href,
  isLoading,
  onClick,
  size,
  startIcon,
  endIcon,
  sx,
  dataTestId,
  iconColor = 'inherit',
  iconStyle,
  loadingSpinnerColor = 'inherit',
  loadingSpinnerSize,
  color = null,
}: ButtonProps) => {
  const buttonStyles = {
    borderColor: (theme) => theme.palette[color]?.main || theme.palette.primary.main,
    color: (theme) => theme.palette[color]?.main || theme.palette.primary.main,
    '&:hover': {
      borderColor: (theme) =>
        // Prevents hover color when the button is disabled or loading to avoid stuck hover state on mobile.
        !disabled && !isLoading
          ? theme.palette[color]?.dark || theme.palette.primary.dark
          : undefined,
    },
    ...sx,
  };
  return (
    <StyledSecondaryButton
      disabled={disabled}
      onClick={onClick}
      size={size}
      variant="outlined"
      startIcon={
        !isLoading &&
        startIcon && (
          <Box
            component="span"
            sx={{
              ...iconStyles(iconColor, iconStyle),
            }}
          >
            {startIcon}
          </Box>
        )
      }
      endIcon={
        !isLoading &&
        endIcon && (
          <Box
            component="span"
            sx={{
              ...iconStyles(iconColor, iconStyle),
            }}
          >
            {endIcon}
          </Box>
        )
      }
      sx={buttonStyles}
      href={href}
      data-testid={dataTestId ? dataTestId : 'secondary-button'}
    >
      {isLoading ? (
        <CircularProgress color={loadingSpinnerColor} size={loadingSpinnerSize} />
      ) : (
        children
      )}
    </StyledSecondaryButton>
  );
};

export const iconStyles = (iconColor: string, iconStyle: SxProps<Theme>): SxProps<Theme> => ({
  color: iconColor,
  display: 'flex',
  alignItems: 'center',
  ...iconStyle,
});
