import { TextField, Typography, useTheme } from '@mui/material';

import { ATSJobRequestUpdateTestIds } from 'data-testids/ATS';
import { CloseButton } from 'sharedComponents/Buttons';
import { DenialFormProps } from '../types';
import {
  CloseButtonAndHelpText,
  ErrorText,
  DenialFormContainer,
  FormOptions,
  ReturnBox,
  TitleContainer,
} from './styles';
import { ToggleButtons } from 'sharedComponents/Buttons/ToggleButtons';
import { denialOptions } from '../data';
import { Controller, useForm } from 'react-hook-form';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { JobRequestAction } from '../enum';

export const DenialForm: React.FC<DenialFormProps> = ({
  currentlySelectedToggle,
  hasPendingMutation,
  onDenialSubmit,
  onInnerFormClose,
  onToggle,
  action,
}) => {
  const theme = useTheme();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleFormSubmit = (data, event) => {
    event.preventDefault();
    const { noteText } = data;
    onDenialSubmit(noteText);
  };

  return (
    <DenialFormContainer onSubmit={handleSubmit(handleFormSubmit)}>
      <FormOptions>
        <CloseButtonAndHelpText>
          <CloseButton
            dataTestId={ATSJobRequestUpdateTestIds.INNER_FORM_CLOSE_BUTTON}
            onClick={onInnerFormClose}
          />
          <TitleContainer>
            <Typography>What would you like to do with this request?</Typography>
          </TitleContainer>
        </CloseButtonAndHelpText>

        <ToggleButtons
          options={denialOptions}
          onToggle={onToggle}
          currentlySelected={currentlySelectedToggle}
          dataTestId={ATSJobRequestUpdateTestIds.DENIAL_TOGGLE_BUTTONS}
        />
        {action === JobRequestAction.return && (
          <ReturnBox>
            <Typography
              sx={{ color: theme.palette.text.tertiary, paddingBottom: theme.spacing(1) }}
            >
              The request will be bounced back to the sender’s drafts, where (s)he can edit and
              resubmit for approval.
            </Typography>
            <Controller
              name="noteText"
              control={control}
              rules={{ required: 'Please enter a note' }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={Boolean(errors?.noteText)}
                  fullWidth
                  multiline
                  onChange={onChange}
                  placeholder={`Type your note here`}
                  rows={6}
                  sx={{ backgroundColor: theme.palette.white.main }}
                  value={value}
                  data-testid={ATSJobRequestUpdateTestIds.RETURN_TEXT_BOX}
                />
              )}
            />
            {errors?.noteText?.message && (
              <ErrorText data-testid={ATSJobRequestUpdateTestIds.RETURN_TEXT_BOX_ERROR_MESSAGE}>
                {errors.noteText.message}
              </ErrorText>
            )}
          </ReturnBox>
        )}
      </FormOptions>
      <PrimaryButton
        dataTestId={ATSJobRequestUpdateTestIds.SECONDARY_DENIAL_BUTTON}
        color="black"
        type="submit"
        disabled={Boolean(errors?.noteText) || hasPendingMutation}
        sx={{
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
        }}
      >
        Deny Request
      </PrimaryButton>
    </DenialFormContainer>
  );
};
