import { Input } from 'sharedComponents/Input';
import { Label } from 'sharedComponents/Label';
import { InputProps } from './types';

export const InputAndLabel: React.FC<InputProps> = ({
  hasError,
  label,
  onChange,
  placeholder,
  testid,
  value,
  sx,
}) => {
  return (
    <>
      <Label labelText={label} />
      <Input
        hasError={hasError}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        dataTestId={testid}
        sx={sx}
      />
    </>
  );
};
