import { ATSNavbarDataTestIds } from 'data-testids/ATS';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import auth from 'utils/auth';

interface DropdownProps {
  logout: () => void;
  getUser: () => {
    id: number;
    first_name: string;
    is_hijacked: boolean;
  };
  releaseHijack: () => void;
  showChecklist: () => boolean;
  inPostingOnlyDistrict: boolean;
  isDistrictAdmin: boolean;
  isDistrictUser: boolean;
  isSchoolUser: boolean;
  showDropdown: boolean;
  jobBoardStyling: boolean;
  combinedPreferenceAndProfile?: boolean;
}

interface DropdownContentProps {
  isOpen: boolean;
}

interface JobBoardStylingProps {
  jobBoardStyling: boolean;
}

function UserDropdown(props: DropdownProps) {
  const candidatesDropdownNode = useRef<HTMLDivElement | null>(null);
  const [showDropdown, setShowDropdown] = useState(props.showDropdown);
  const history = useHistory();
  const user = auth.getUser();

  useEffect(() => {
    document.addEventListener('mousedown', closeDropdown, { capture: true });
    return () => {
      document.removeEventListener('mousedown', closeDropdown);
    };
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const closeDropdown = (event) => {
    if (candidatesDropdownNode.current && !candidatesDropdownNode.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  return (
    <Dropdown onClick={closeDropdown} ref={candidatesDropdownNode}>
      <DropdownBtn
        jobBoardStyling={props.jobBoardStyling}
        onClick={toggleDropdown}
        data-testid={ATSNavbarDataTestIds.USER_NAME}
      >
        {user?.first_name}&nbsp;
        <i className="fa fa-caret-down" />
      </DropdownBtn>
      <DropdownContent isOpen={showDropdown}>
        {props.isDistrictUser ? (
          <>
            {props.inPostingOnlyDistrict && (
              <NavLink
                jobBoardStyling={props.jobBoardStyling}
                onClick={() => {
                  toggleDropdown();
                  history.push('/district/jobslist');
                }}
              >
                <span>Post jobs</span>
              </NavLink>
            )}
            {props.isDistrictAdmin && (
              <NavLink
                jobBoardStyling={props.jobBoardStyling}
                onClick={() => {
                  toggleDropdown();
                  history.push('/district/account');
                }}
              >
                <span>Account</span>
              </NavLink>
            )}
            {!props.inPostingOnlyDistrict && (
              <>
                <NavLink
                  jobBoardStyling={props.jobBoardStyling}
                  onClick={() => {
                    toggleDropdown();
                    history.push('/district/settings');
                  }}
                >
                  <span>Settings</span>
                </NavLink>
                <NavLink
                  jobBoardStyling={props.jobBoardStyling}
                  onClick={() => {
                    toggleDropdown();
                    const win = window.open('https://www.hirenimble.com/tutorials', '_blank');
                    if (win !== null) {
                      win.focus();
                    }
                  }}
                >
                  <span>Tutorials</span>
                </NavLink>
              </>
            )}
            {user.is_hijacked && (
              <NavLink
                jobBoardStyling={props.jobBoardStyling}
                onClick={() => {
                  toggleDropdown();
                  auth.handleReleaseHijack();
                }}
              >
                <span>Release Hijack</span>
              </NavLink>
            )}
            {[26241, 28836, 52860].includes(user.id) && (
              <NavLink
                jobBoardStyling={props.jobBoardStyling}
                onClick={() => window.open('/api/pull-slps-data/', '_blank')}
              >
                <span>Export data</span>
              </NavLink>
            )}
            {props.showChecklist() && (
              <NavLink
                jobBoardStyling={props.jobBoardStyling}
                onClick={() => {
                  toggleDropdown();
                  history.push('/getting-started');
                }}
              >
                <span>Getting started</span>
              </NavLink>
            )}
          </>
        ) : props.isSchoolUser ? (
          <>
            <NavLink
              jobBoardStyling={props.jobBoardStyling}
              onClick={() => {
                toggleDropdown();
                history.push('/school/account');
              }}
            >
              <span>Account</span>
            </NavLink>
            <NavLink
              jobBoardStyling={props.jobBoardStyling}
              onClick={() => {
                toggleDropdown();
                history.push('/school/settings');
              }}
            >
              <span>Settings</span>
            </NavLink>
            <NavLink
              jobBoardStyling={props.jobBoardStyling}
              onClick={() => {
                toggleDropdown();
                const win = window.open('https://www.hirenimble.com/tutorials', '_blank');
                if (win !== null) {
                  win.focus();
                }
              }}
            >
              <span>Tutorials</span>
            </NavLink>
            {user.is_hijacked && (
              <NavLink
                jobBoardStyling={props.jobBoardStyling}
                onClick={() => {
                  toggleDropdown();
                  auth.handleReleaseHijack();
                }}
              >
                <span>Release Hijack</span>
              </NavLink>
            )}
            {props.showChecklist() && (
              <NavLink
                jobBoardStyling={props.jobBoardStyling}
                onClick={() => {
                  toggleDropdown();
                  history.push('/getting-started');
                }}
              >
                <span>Getting started</span>
              </NavLink>
            )}
          </>
        ) : (
          <>
            <NavLink
              jobBoardStyling={props.jobBoardStyling}
              onClick={() => {
                toggleDropdown();
                props.combinedPreferenceAndProfile
                  ? history.push('/preferences')
                  : history.push('/applicant-profile/edit-profile');
              }}
            >
              <span>Profile</span>
            </NavLink>

            <NavLink
              jobBoardStyling={props.jobBoardStyling}
              onClick={() => {
                toggleDropdown();
                history.push('/candidate-dashboard');
              }}
            >
              <span>Dashboard</span>
            </NavLink>

            {!props.combinedPreferenceAndProfile && (
              <NavLink
                jobBoardStyling={props.jobBoardStyling}
                onClick={() => {
                  toggleDropdown();
                  history.push('/preferences');
                }}
              >
                <span>Preferences</span>
              </NavLink>
            )}

            {user?.is_hijacked && (
              <NavLink
                jobBoardStyling={props.jobBoardStyling}
                onClick={() => {
                  toggleDropdown();
                  auth.handleReleaseHijack();
                }}
              >
                <span>Release Hijack</span>
              </NavLink>
            )}
          </>
        )}
        <NavLink
          jobBoardStyling={props.jobBoardStyling}
          onClick={() => {
            toggleDropdown();
            history.push('/');
            props.logout();
          }}
        >
          <span>Log out</span>
        </NavLink>
      </DropdownContent>
    </Dropdown>
  );
}

export default UserDropdown;

const Dropdown = styled.div`
  position: relative;
  display: block;
  float: right;
  color: rgba(57, 60, 73, 0.8);
  font-size: 13px;
  background-color: white;
`;

const DropdownBtn = styled.button<JobBoardStylingProps>`
  color: #4f4f4f;
  font-size: ${(props) => (props.jobBoardStyling ? '15px' : '13px')};
  font-weight: ${(props) => props.jobBoardStyling && '15px'};
  padding: ${(props) => props.jobBoardStyling && '12px 0px 3px 0px'};
  font-weight: ${(props) => props.jobBoardStyling && '600'};
  background-color: white;
  border: none;
  cursor: pointer;
`;

const DropdownContent = styled.div<DropdownContentProps>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  right: 0;
  z-index: 1;
  color: rgba(57, 60, 73, 0.8);
  font-size: 13px;
  background-color: white;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  cursor: pointer;
`;

const NavLink = styled.div<JobBoardStylingProps>`
  padding: 12px 16px;
  cursor: pointer;
  font-weight: ${(props) => props.jobBoardStyling && '600'};

  &:hover {
    color: var(--green);
    transition: color 0.2s linear;
  }
`;
