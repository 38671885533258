import { Box } from '@mui/material';

import { DetailsContainer, Key, Row, Value } from './styles';
import { JobRequestDetailsProps } from '../types';
import { getGradesForJobView, getSubjectsForJobView } from 'features/JobView/utils';
import { makeAllUrlsValid } from 'utils/util';
import { useQueryGetJobById, useQueryGetSubcategoriesByDistrictId } from '../queries';
import { ATSJobRequestUpdateTestIds } from 'data-testids/ATS';

export const JobRequestDetails: React.FC<JobRequestDetailsProps> = ({ requester, id }) => {
  const { data } = useQueryGetJobById(id, {
    refetchOnMount: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
  });
  const { district, title, requisition_number, school, subjects, grades, internal_role_notes } =
    data;
  const { data: subcategories } = useQueryGetSubcategoriesByDistrictId(district.id, {
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
  });

  return (
    <DetailsContainer data-testid={ATSJobRequestUpdateTestIds.DETAILS_CONTAINER}>
      <Row data-testid={ATSJobRequestUpdateTestIds.REQUESTER}>
        <Key>Requester:</Key>&nbsp;
        <Value>{requester}</Value>
      </Row>

      <Row data-testid={ATSJobRequestUpdateTestIds.REQUEST_TITLE}>
        <Key>Job title:</Key>&nbsp;
        {title}
      </Row>

      {requisition_number && (
        <Row data-testid={ATSJobRequestUpdateTestIds.REQUISITION_NUMBER}>
          <Key>Requisition number:</Key>&nbsp;
          <Value>{requisition_number}</Value>
        </Row>
      )}

      {school && (
        <Row data-testid={ATSJobRequestUpdateTestIds.LOCATION}>
          <Key>Location:</Key>&nbsp;
          <Value>{school.name}</Value>
        </Row>
      )}

      {subjects?.length > 0 && subcategories && (
        <Row data-testid={ATSJobRequestUpdateTestIds.CATEGORIES}>
          <Key>Category:</Key>&nbsp;
          <Value> {getSubjectsForJobView(data, subcategories)}</Value>
        </Row>
      )}

      {grades?.length > 0 && (
        <Row data-testid={ATSJobRequestUpdateTestIds.GRADES}>
          <Key>Grades:</Key>&nbsp;
          <Value>{getGradesForJobView(data)}</Value>
        </Row>
      )}

      {internal_role_notes?.length > 0 && (
        <Row
          sx={{ flexDirection: 'column' }}
          data-testid={ATSJobRequestUpdateTestIds.INTERNAL_ROLE_NOTES}
        >
          <Key>Internal notes:</Key>

          {internal_role_notes.map((note) => (
            <Box
              key={note.id}
              sx={{ fontStyle: 'italic' }}
              dangerouslySetInnerHTML={{
                __html: makeAllUrlsValid(note.text),
              }}
            />
          ))}
        </Row>
      )}
    </DetailsContainer>
  );
};
