import { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import moment from 'moment';
import auth from '../../../utils/auth';
import CloseIcon from '../../../assets/icon-close-x-dark.svg';
import whiteCheckmarkIcon from '../../../ui-kit/icons/svg/white-checkmark.svg';
import { makeAllUrlsValid } from '../../../utils/util';
import DeleteModal from '../DeleteModal';
import 'quill-mention';

import { InternalRoleNote } from '../../../types';
import { User, FetchedAuthUser } from 'types';

import NotesList from './NotesList';

import { isNoteAuthor } from './util';

export interface InternalRoleNoteProps {
  notes: InternalRoleNote[];
  addNote: (note: InternalRoleNote) => void;
  updateNote: (note: InternalRoleNote, index: number) => void;
  adminUsers: User[];
  setUnsubmittedInternalNote: (note: InternalRoleNote) => void;
}

export default function InternalRoleNotes({
  notes,
  addNote,
  updateNote,
  adminUsers,
  setUnsubmittedInternalNote,
}: InternalRoleNoteProps): React.ReactElement {
  const currentUser: FetchedAuthUser = auth.getUser();

  const [quillInputString, setQuillInputString] = useState<string>('');
  const [noteCurrentlyBeingEdited, setNoteCurrentlyBeingEdited] = useState<InternalRoleNote | null>(
    null
  );
  const [currentlyEditingIndex, setCurrentlyEditingIndex] = useState<number | null>(null);
  const [currentlyDeletingIndex, setCurrentlyDeletingIndex] = useState<number | null>(null);
  const [noteToDelete, setNoteToDelete] = useState<InternalRoleNote | null>(null);
  const [adminUsersFormatted, setAdminUsersFormatted] = useState<User[] | []>([]);
  const [displayQuill, setDisplayQuill] = useState<boolean>(false);

  useEffect(() => {
    if (adminUsers.length > 0 && !displayQuill) {
      adminUsers.forEach((user) => (user['value'] = user.display));
      const onlySuperAndDistrictAdminUsers = adminUsers.filter((user) =>
        auth.isDistrictAdmin(user)
      );
      setAdminUsersFormatted(onlySuperAndDistrictAdminUsers);

      //Note: hacky fix but waiting until editor loads to prevent autofocus.
      const timer = setTimeout(() => {
        setDisplayQuill(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [adminUsers, displayQuill]);

  const modules = useMemo(
    () => ({
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        source: function (searchTerm, renderItem, mentionChar) {
          let values = [];
          if (mentionChar === '@') {
            values = adminUsersFormatted;
          }
          if (searchTerm.length === 0) {
            renderItem(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values?.length; i++)
              if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
                matches.push(values[i]);
            renderItem(matches, searchTerm);
          }
        },
      },
    }),
    [adminUsersFormatted]
  );

  /**
   * Proxy onChange handler for ReactQuill that is passed the following args.
   *
   * @param htmlContentString a string with embeded HTML content (ie. "<p>Foo</p>").
   * @param delta JSON like formatter used to describe changes. https://quilljs.com/docs/delta/
   * @param source the source of the change
   * @param editor read-only proxy for accessing methods: editer.getHTML(), editer.getText(), etc.
   */
  const handleInputChange = (htmlContentString: string): void => {
    if (!htmlContentString) return;

    const tagged_users = getTaggedUsers(htmlContentString);
    const newNote = {
      tagged_users,
      text: htmlContentString,
      created_by: currentUser,
      created_by_id: currentUser.id,
      created_at: new Date(moment.now()).toISOString(),
      is_edited: false,
      newly_tagged_users: tagged_users,
    };

    setUnsubmittedInternalNote(newNote);
    setQuillInputString(htmlContentString);
  };

  const handleUpdateNote = (): void => {
    if (!quillInputString || quillInputString === noteCurrentlyBeingEdited.text) return;

    const text = makeAllUrlsValid(quillInputString);
    const editedNote = noteCurrentlyBeingEdited;
    editedNote.text = text;
    editedNote.is_edited = true;
    editedNote.tagged_users = getTaggedUsers(text);
    editedNote.newly_tagged_users = getNewlyTaggedUsers(
      noteCurrentlyBeingEdited.tagged_users,
      editedNote.tagged_users
    );

    updateNote(editedNote, currentlyEditingIndex);
    clearStaging();
  };

  const handleDeleteNote = (): void => {
    const noteToDeleteCopy = noteToDelete;
    noteToDeleteCopy.is_deleted = true;
    updateNote(noteToDeleteCopy, currentlyDeletingIndex);
    setNoteToDelete(null);
    setCurrentlyDeletingIndex(null);
  };

  const getTaggedUsers = (htmlString: string): number[] => {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    const selected = div.getElementsByClassName('mention');
    const taggedUsers = [];
    for (const mention of selected) {
      // TODO: i'm not sure what dataset is supposed to mean here, so i'm ignoring the error and leaving as is for now
      // @ts-expect-error 'dataset' is not a property of 'Element'
      taggedUsers.push(mention.dataset.id);
    }
    return taggedUsers;
  };

  // only newlytagged users will be sent notifications
  const getNewlyTaggedUsers = (
    previouslyTaggedUsers: number[],
    taggedUsers: number[]
  ): number[] => {
    const newlyTaggedUsers = [];
    for (const taggedUser of taggedUsers) {
      if (!previouslyTaggedUsers.includes(+taggedUser)) {
        newlyTaggedUsers.push(taggedUsers);
      }
    }
    return newlyTaggedUsers;
  };

  const handleAddNote = (): void => {
    if (!quillInputString) return;

    const text = makeAllUrlsValid(quillInputString);
    const tagged_users = getTaggedUsers(text);
    const newNote = {
      text,
      created_by: currentUser,
      created_by_id: currentUser.id,
      created_at: new Date(moment.now()).toISOString(),
      is_edited: false,
      tagged_users,
      newly_tagged_users: tagged_users, // for new notes, all tagged users are considered new
    };

    addNote(newNote);
    clearStaging();
  };

  const handleDeleteClick = (note: InternalRoleNote, index: number): void => {
    setCurrentlyDeletingIndex(index);
    setNoteToDelete(note);
  };

  const handleEditClick = (note: InternalRoleNote, index: number): void => {
    setQuillInputString(note.text);
    setNoteCurrentlyBeingEdited(note);
    setCurrentlyEditingIndex(index);
  };

  const clearStaging = () => {
    setUnsubmittedInternalNote(null);
    setCurrentlyEditingIndex(null);
    setNoteCurrentlyBeingEdited(null);
    setQuillInputString('');
  };

  const getAuthorFirstName = (note: InternalRoleNote): string => {
    const first_name = note?.created_by?.first_name;
    return first_name || 'this user';
  };

  return (
    <div>
      <NotesList
        internalRoleNotes={notes}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
      />
      {noteToDelete && (
        <DeleteModal
          show={true}
          onHide={() => setNoteToDelete(null)}
          onSave={handleDeleteNote}
          title={'Are you sure?'}
          message={`Are you sure you want to delete ${
            isNoteAuthor(noteToDelete, currentUser)
              ? 'your'
              : `${getAuthorFirstName(noteToDelete)}'s`
          } note? This action cannot be undone.`}
          cancelLabel={'Cancel'}
          submitLabel={'Yes, delete note'}
        />
      )}
      {auth.isDistrictAdmin() ? (
        <div
          data-testid="mention-quill"
          style={{
            display: displayQuill ? undefined : 'none',
          }}
        >
          <StyledReactQuill
            theme="snow"
            modules={modules}
            value={quillInputString}
            onChange={handleInputChange}
            placeholder={`Notes are only visible to District & Super Admin users. Mention people using '@'`}
          />
        </div>
      ) : (
        <StyledReactQuill
          theme="snow"
          value={quillInputString}
          placeholder={'What else should your recruiter or HR team know about this request?'}
          onChange={handleInputChange}
        />
      )}
      {noteCurrentlyBeingEdited ? (
        <EditNoteContainer>
          <CancelEditButton onClick={clearStaging}>
            <Icon src={CloseIcon} />
          </CancelEditButton>
          <SaveNoteButton onClick={handleUpdateNote}>
            {' '}
            <Icon src={whiteCheckmarkIcon} />
          </SaveNoteButton>
        </EditNoteContainer>
      ) : (
        <AddNoteButton onClick={handleAddNote}>Add Note</AddNoteButton>
      )}
      <BufferDiv />
    </div>
  );
}

// style=clear keeps the floated button in the document flow
const BufferDiv = styled.div`
  clear: both;
`;

const AddNoteButton = styled.button`
  background-color: #00b88d;
  font-size: 14px;
  margin-right: 0;
  float: right;
  color: white;
  border-radius: 3px;
  border: none;
  padding: 0 20px;
  width: 155px;
  height: 49px !important;
  ${(props) => (props.disabled ? 'cursor: not-allowed' : '')};
  ${(props) => (props.disabled ? 'opacity: 0.7' : '')};
`;

const Icon = styled.img`
  width: 300px;
  padding: 5px;
`;

const CancelEditButton = styled.button`
  color: white;
  border-radius: 3px;
  padding: 10px;
  border: #a3a2b4;
  background-color: white;
  border-style: solid;
  border-width: 2px;
  width: 49px;
  height: 49px !important;
  ${(props) => (props.disabled ? 'cursor: not-allowed' : '')};
  ${(props) => (props.disabled ? 'opacity: 0.7' : '')};
`;

const SaveNoteButton = styled.button`
  font-size: 14px;
  border-radius: 3px;
  border: none;
  margin-left: 5px;
  background-color: #00b88d;
  width: 49px;
  height: 49px !important;
  ${(props) => (props.disabled ? 'cursor: not-allowed' : '')};
  ${(props) => (props.disabled ? 'opacity: 0.7' : '')};
`;

const EditNoteContainer = styled.div`
  float: right;
`;

const StyledReactQuill = styled(ReactQuill)`
  margin-bottom: 16px;

  &:hover {
    border-color: #dcdcdc !important;
  }
  .ql-editor {
    min-height: 160px;
  }

  .ql-editor.ql-blank::before {
    color: rgba(57, 60, 73, 0.3);
  }
  .ql-mention-list-container-bottom {
    background-color: white;
    width: 225px;
    max-height: 223px;
    overflow: auto;
    position: absolute;
    bottom: 14;
    left: -19;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
    list-style: none;
    ul {
      list-style-type: none;
    }
  }

  .mention {
    background-color: #d9e1f0;
    font-weight: bold;
  }
`;
