import axios from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import rolesAPI from 'api/rolesAPI';
import { JobviewJob } from 'types';
import { Option } from 'hooks/data/useStateFilterOptions';
import roleSearchAPI from 'api/roleSearchAPI';
import schoolrolesAPI from 'api/schoolrolesAPI';

export const useQueryGetJobById = (
  jobId: number,
  options?: { [option: string]: boolean | number }
): UseQueryResult<JobviewJob, unknown> => {
  return useQuery({
    ...options,
    queryKey: ['job', jobId],
    queryFn: () => rolesAPI.fetchRole(jobId).then((res) => res),
    enabled: jobId !== undefined && jobId !== null,
    refetchOnMount: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
  });
};

export const fetchSubcategoriesByDistrictId = async (districtId: number): Promise<unknown> => {
  return axios.get(`/api/categories/`, { params: { district_id: districtId } }).then((r) => {
    const categories = r.data || [];

    return (
      categories.length > 0 &&
      categories
        ?.map((category) => {
          return category.subcategories.map((subcategory) => {
            return {
              value: subcategory.id,
              label: subcategory.label,
            };
          });
        })
        .flat()
    );
  });
};

export const useQueryGetSubcategoriesByDistrictId = (
  districtId: number,
  options?: { [option: string]: boolean | number }
): UseQueryResult<Option[], unknown> => {
  return useQuery({
    ...options,
    queryKey: ['subcategories', districtId],
    queryFn: () => fetchSubcategoriesByDistrictId(districtId),
    enabled: districtId !== undefined && districtId !== null,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
  });
};

export const archiveRequestById = async (jobId: number): Promise<unknown> => {
  return rolesAPI.archiveRole(jobId).then((res) => res);
};

export const getJobsBySearchInput = (inputValue: string): Promise<JobviewJob[]> => {
  return roleSearchAPI.searchDistrictRoles(inputValue).then((res) => res.results);
};

export const useQueryGetJobsBySearchInput = (inputValue: string) => {
  return useQuery({
    queryKey: ['jobToMergeRequestInto', inputValue],
    queryFn: () => getJobsBySearchInput(inputValue),
  });
};

export const mergeRoleIntoAnotherRole = (roleToMerge, roleMergingInto) => {
  return rolesAPI.mergeRole(roleToMerge, roleMergingInto).then((res) => res);
};

export const createSchoolRole = (id, newSchoolRole) => {
  return schoolrolesAPI.createSchoolrole(id, newSchoolRole).then((res) => res);
};

export const getTemplatesBySearchInput = (inputValue: string): Promise<JobviewJob[]> => {
  return roleSearchAPI
    .searchTemplates(inputValue)
    .then((res) => res.results)
    .catch((error) => error);
};

export const useQueryGetTemplatesBySearchInput = (inputValue: string) => {
  return useQuery({
    queryKey: ['jobToMergeRequestInto', inputValue],
    queryFn: () => getTemplatesBySearchInput(inputValue),
  });
};

export const convertRequestToRole = (title, requestId, templateId) => {
  return rolesAPI.createRoleFromRequest(title, requestId, templateId);
};

export const returnWithNote = async (
  jobId: number,
  payload: { text: string; created_at: Date }
): Promise<unknown> => {
  return rolesAPI.returnSubmittedRole(jobId, payload).then((res) => res);
};
