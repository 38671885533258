import { Box } from '@mui/material';
import { JobQuickActionsProps } from '../../components/DistrictJobsList/DistrictJobRow/types';
import { TooltipIconButton } from 'sharedComponents/Buttons/TooltipIconButton';
import {
  ArrowForwardIos,
  AssignmentReturn,
  CallMerge,
  ContentCopy,
  ControlPointDuplicate,
  Edit,
  HighlightOff,
  Inventory,
  OpenInFull,
  Share,
  Unarchive,
} from '@mui/icons-material';
import auth from 'utils/auth';
import styled from 'styled-components';
import { roleStatusByValue } from 'utils/enums';
import { ATSJobsListQuickActionTestIds } from 'data-testids/ATS';
import { JobRequestAction } from 'features/JobRequestUpdate/enum';

const ArchiveButton = ({ archive, jobIsArchived }) => {
  const tooltipText = jobIsArchived ? 'Unarchive' : 'Archive';

  return (
    <TooltipIconButton
      tooltipTitle={tooltipText}
      onClick={(event) => {
        event.stopPropagation();
        archive(event);
      }}
      dataTestId={ATSJobsListQuickActionTestIds.ARCHIVE_JOB}
    >
      {jobIsArchived ? <Unarchive /> : <Inventory />}
    </TooltipIconButton>
  );
};

const CloseButton = ({ close, jobIsActiveOpen }) => {
  const tooltipText = jobIsActiveOpen ? 'Close' : 'Open';

  return (
    <TooltipIconButton
      tooltipTitle={tooltipText}
      onClick={(event) => close(event)}
      dataTestId={ATSJobsListQuickActionTestIds.CLOSE_JOB}
    >
      {jobIsActiveOpen ? <HighlightOff /> : <OpenInFull />}
    </TooltipIconButton>
  );
};

export const JobQuickActions: React.FC<JobQuickActionsProps> = ({
  archive,
  close,
  duplicateRole,
  handleOpenModalClick,
  handleSetModalInitialState,
  job,
  openMergeRoleModal,
  openReturnSubmittedRoleModal,
  openShareModal,
  showSocialMediaShareButton,
  visitJob,
}) => {
  const jobIsActive = job.status === roleStatusByValue.active;
  const jobIsActiveOpen = job.active_status === 1;
  const jobIsArchived = job.status === 30;
  const jobIsRequest = job.status === 10;
  const userIsDistrictOrSuperAdmin = auth.isDistrictAdmin();
  const inPostingOnlyCantCloseJobs = auth.inPostingOnlyDistrict();
  const canCreateTemplates = !jobIsRequest && auth.hasTemplates();

  const shouldNotShowEditAction =
    jobIsRequest && auth.hasJobRequestUpdate() && auth.isDistrictAdmin();

  return (
    <ActionsContainer data-testid="job-quick-action-buttons">
      {userIsDistrictOrSuperAdmin && (
        <>
          {canCreateTemplates && (
            <TooltipIconButton
              onClick={(event) => handleOpenModalClick(event, job)}
              tooltipTitle="Create template from job"
              dataTestId={ATSJobsListQuickActionTestIds.TEMPLATE_FROM_JOB}
            >
              <ControlPointDuplicate sx={{ color: '#909090' }} />
            </TooltipIconButton>
          )}
          <ArchiveButton jobIsArchived={jobIsArchived} archive={archive} />

          {jobIsActive && !inPostingOnlyCantCloseJobs && (
            <CloseButton jobIsActiveOpen={jobIsActiveOpen} close={close} />
          )}

          {!jobIsRequest && (
            <TooltipIconButton
              tooltipTitle="Duplicate"
              onClick={(event) => {
                event.stopPropagation();
                duplicateRole(job.id);
              }}
              dataTestId={ATSJobsListQuickActionTestIds.DUPLICATE_JOB}
            >
              <ContentCopy />
            </TooltipIconButton>
          )}

          {jobIsRequest && (
            <>
              <TooltipIconButton
                tooltipTitle="Return"
                onClick={(event) => {
                  event.stopPropagation();
                  if (auth.hasJobRequestUpdate()) {
                    handleSetModalInitialState(job.id, JobRequestAction.return);
                  } else {
                    openReturnSubmittedRoleModal(job.id, true);
                  }
                }}
                dataTestId={ATSJobsListQuickActionTestIds.RETURN_JOB}
              >
                <AssignmentReturn />
              </TooltipIconButton>

              <TooltipIconButton
                tooltipTitle="Merge"
                onClick={(event) => {
                  event.stopPropagation();
                  if (auth.hasJobRequestUpdate()) {
                    handleSetModalInitialState(job.id, JobRequestAction.merge);
                  } else {
                    openMergeRoleModal(job);
                  }
                }}
                dataTestId={ATSJobsListQuickActionTestIds.MERGE_JOB}
              >
                <CallMerge />
              </TooltipIconButton>
            </>
          )}

          {!shouldNotShowEditAction && (
            <TooltipIconButton
              tooltipTitle="Edit"
              onClick={(event) => {
                event.stopPropagation();
                visitJob('jobedit');
              }}
              dataTestId={ATSJobsListQuickActionTestIds.EDIT_JOB}
            >
              <Edit />
            </TooltipIconButton>
          )}

          {showSocialMediaShareButton && (
            <TooltipIconButton
              tooltipTitle="Share"
              onClick={(event) => {
                event.stopPropagation();
                openShareModal(job);
              }}
              dataTestId={ATSJobsListQuickActionTestIds.SHARE_JOB}
            >
              <Share />
            </TooltipIconButton>
          )}

          <TooltipIconButton
            tooltipTitle="View"
            onClick={(event) => {
              event.stopPropagation();
              visitJob('jobview');
            }}
            dataTestId={ATSJobsListQuickActionTestIds.VIEW_JOB}
          >
            <ArrowForwardIos />
          </TooltipIconButton>
        </>
      )}

      {!userIsDistrictOrSuperAdmin && (
        <>
          {jobIsRequest && (
            <TooltipIconButton
              tooltipTitle="Edit"
              dataTestId={ATSJobsListQuickActionTestIds.EDIT_JOB}
            >
              <Edit
                onClick={(event) => {
                  event.stopPropagation();
                  visitJob('jobedit');
                }}
              />
            </TooltipIconButton>
          )}

          {showSocialMediaShareButton && (
            <TooltipIconButton
              tooltipTitle="Share"
              onClick={(event) => {
                event.stopPropagation();
                openShareModal(job);
              }}
              dataTestId={ATSJobsListQuickActionTestIds.SHARE_JOB}
            >
              <Share />
            </TooltipIconButton>
          )}
        </>
      )}
    </ActionsContainer>
  );
};

const ActionsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
});
