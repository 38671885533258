import { useState } from 'react';
import { Autocomplete, Box, TextField, useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { ErrorText, JobToMergeIntoTitle, LowerBox } from './styles';
import useDebounce from '../utils';
import { ATSJobRequestUpdateTestIds } from 'data-testids/ATS';
import { useQueryGetTemplatesBySearchInput } from '../queries';
import { InputAndLabel } from 'sharedComponents/InputAndLabel';
import { Label } from 'sharedComponents/Label';

export interface CreateSubFormProps {
  hasTitleError?: boolean;
}

export const CreateSubForm: React.FC<CreateSubFormProps> = ({ hasTitleError }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useDebounce(searchTerm, 500);

  const theme = useTheme();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { data: searchResults, isLoading } = useQueryGetTemplatesBySearchInput(debouncedSearch);

  return (
    <LowerBox data-testid={ATSJobRequestUpdateTestIds.CREATE_SUBFORM}>
      <Controller
        control={control}
        name="title"
        rules={{ required: 'Please enter a title' }}
        render={({ field: { onChange } }) => {
          return (
            <>
              <InputAndLabel
                label="Job title*"
                onChange={onChange}
                placeholder="Ex: English teacher"
                sx={{ backgroundColor: theme.palette.white.main }}
                hasError={Boolean(errors?.title?.message)}
                testid={ATSJobRequestUpdateTestIds.TITLE_INPUT}
              />
            </>
          );
        }}
      />
      {hasTitleError && (
        <ErrorText data-testid={ATSJobRequestUpdateTestIds.MERGE_ERROR_TEXT}>
          {errors?.title?.message}
        </ErrorText>
      )}
      <Controller
        name="template"
        control={control}
        render={({ field }) => {
          const { onChange } = field;

          return (
            <Box sx={{ marginTop: theme.spacing(2) }}>
              <Label labelText="Template (Optional)" />
              <Autocomplete
                {...field}
                getOptionLabel={(option) => option.title || ''}
                options={searchResults || []}
                onChange={(_e, data) => {
                  return onChange(data);
                }}
                loading={isLoading}
                renderOption={(props, option) => (
                  <Box {...props} component="li" key={option.id}>
                    <JobToMergeIntoTitle>{option.title}</JobToMergeIntoTitle>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    placeholder="Start typing to search"
                    {...params}
                    sx={{ backgroundColor: theme.palette.white.main }}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    error={Boolean(errors?.jobToMergeInto?.message)}
                  />
                )}
              />
            </Box>
          );
        }}
      />
    </LowerBox>
  );
};
