import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import LoadingSpinner from '../../../components/loadingSpinner';
import { ServerErrorModal } from '../../../sharedComponents/Error';
import { AxiosError } from 'axios';

// Overrides to make a height 100% work
const GlobalOverridesStyle = createGlobalStyle`
    body {
        height: 100%;
        padding-bottom: 0;

        .wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;
            padding-bottom: 0;
        }
    }
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

interface TrevorDashboardBodyProps {
  iframeUrl: string;
  isFetching: boolean;
  error?: AxiosError;
}

const TrevorDashboardBody: React.FC<TrevorDashboardBodyProps> = ({
  iframeUrl,
  isFetching,
  error,
}) => {
  let body = null;

  if (isFetching) {
    body = <LoadingSpinner />;
  } else if (error) {
    body = <ServerErrorModal />;
  } else if (iframeUrl) {
    body = <StyledIframe role="iframe" src={iframeUrl} />;
  }

  return (
    <>
      <GlobalOverridesStyle />
      {body}
    </>
  );
};

export default TrevorDashboardBody;
