export type NavbarItem = { [key: string]: string };

const sharedItems: NavbarItem = {
  home: 'Home',
  candidatesMain: 'Candidates',
  candidatesAll: 'View All',
  candidatesPosting: 'By Posting',
  jobs: 'Jobs',
};

export const schoolItems: NavbarItem = {
  ...sharedItems,
  dashboard: 'Dashboard',
};

export const districtItems: NavbarItem = {
  ...sharedItems,
  dashboardsMain: 'Dashboards',
  dashboardsVacancy: 'Vacancy metrics',
  dashboardsPipeline: 'Pipeline metrics',
  prospects: 'Prospects',
};

export const stateItems: NavbarItem = {
  ...sharedItems,
  districtDashboardsMain: 'District Dashboards',
  districtDashboardsVacancy: 'Vacancy metrics',
  districtDashboardsPipeline: 'Pipeline metrics',
  stateDashboardsMain: 'State Dashboards',
  stateDashboardsApplicant: 'Applicant Characteristics',
  stateDashboardsVacancy: 'Vacancy Characteristics',
  stateDashboardsSupply: 'Supply & Demand',
  stateDashboardsOECOSL: 'OECOSL Data Dashboard',
  prospects: 'Prospects',
};

export const postingOnlyItems: NavbarItem = {
  main: 'Post jobs',
};
