import TabbedJobView from 'features/TabbedJobView/TabbedJobView';
import auth from 'utils/auth';
import JobviewContainer from './JobviewContainer';

const JobViewWrapper: React.FC = () => {
  const isAdmin = auth.isAdminUser();
  const hasTabbedJobView = auth.hasTabbedJobView();

  return <>{isAdmin && hasTabbedJobView ? <TabbedJobView /> : <JobviewContainer />}</>;
};

export default JobViewWrapper;
