import { useMutation } from '@tanstack/react-query';

import schoolsAPI from 'api/schoolsAPI';
import { JobviewJob } from 'types';
import { UseMutation } from 'types/types';
import {
  archiveRequestById,
  convertRequestToRole,
  createSchoolRole,
  mergeRoleIntoAnotherRole,
  returnWithNote,
} from './queries';
import { constructSchoolroleFromMergedRole } from 'utils/roleutils';
import moment from 'moment';

interface ArchiveMutationVariables {
  roleId: number;
}

export const useArchiveRequestMutation: UseMutation<
  unknown,
  unknown,
  ArchiveMutationVariables,
  unknown
> = (options) => {
  return useMutation({
    ...options,
    mutationFn: ({ roleId }) => {
      return archiveRequestById(roleId).then((res) => res);
    },
  });
};

export interface CreateReturnNoteMutationVariables {
  roleId: number;
  noteText: string;
}

export const useCreateReturnNoteMutation: UseMutation<
  unknown,
  unknown,
  CreateReturnNoteMutationVariables,
  unknown
> = (options) => {
  return useMutation({
    ...options,
    mutationFn: ({ roleId, noteText }) => {
      const payload = {
        text: noteText,
        created_at: new Date(moment.now()),
      };

      return returnWithNote(roleId, payload).then((res) => res);
    },
  });
};

interface UseMergeRolesMutationVariables {
  roleToMergeInto: JobviewJob;
  role: JobviewJob;
}

export const useMergeRolesMutation: UseMutation<
  unknown,
  unknown,
  UseMergeRolesMutationVariables,
  unknown
> = (options) => {
  return useMutation({
    ...options,
    mutationFn: ({ roleToMergeInto, role }) => {
      const updatedRoleBeingMerged = { ...role };

      if (typeof role.school === 'number') {
        schoolsAPI.fetchSchool(role.school).then((response) => {
          updatedRoleBeingMerged.school = response;
        });
      }

      return mergeRoleIntoAnotherRole(updatedRoleBeingMerged, roleToMergeInto).then((response) => {
        if (response !== 'Role merged.') {
          const newSchoolRole = constructSchoolroleFromMergedRole(response, updatedRoleBeingMerged);

          createSchoolRole(response.id, newSchoolRole).then((res) => res);
        }
      });
    },
  });
};

interface useConvertToJobMutationVariables {
  title: string;
  requestId: number;
  templateId?: number;
}

export const useConvertToJobMutation: UseMutation<
  unknown,
  unknown,
  useConvertToJobMutationVariables,
  unknown
> = (options) => {
  return useMutation({
    ...options,
    mutationFn: ({ title, requestId, templateId }) => {
      return convertRequestToRole(title, requestId, templateId).then((response) => response);
    },
  });
};
