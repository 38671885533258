import { SxProps, useTheme } from '@mui/system';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const RadioCardToggle: React.FC<{
  text: string;
  radioValue: string | boolean;
  selectedValue: string | boolean;
  width?: number | string;
  height?: number | string;
  fontSize?: number | string;
  disabled?: boolean;
  sx?: SxProps;
  dataTestId?: string;
}> = ({
  text,
  radioValue,
  selectedValue,
  width,
  height,
  fontSize,
  disabled = false,
  sx,
  dataTestId,
}) => {
  const theme = useTheme();

  const defaultSx = {
    width: width || 242,
    height: height || '54px',
    border: `2px solid ${selectedValue === radioValue ? theme.palette.primary.medium : theme.palette.border.main}`,
    borderRadius: '12px',
    padding: '0px 8px',
    color: '#101828',
    fontSize: fontSize || '1rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  };

  return (
    <FormControlLabel
      value={radioValue}
      data-testid={dataTestId}
      label={text}
      control={<Radio checkedIcon={<CheckCircleRoundedIcon color="primary" />} />}
      disabled={disabled}
      sx={{ ...defaultSx, ...sx }}
    />
  );
};

export default RadioCardToggle;
