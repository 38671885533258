import { JobviewJob } from 'types';
import moment from 'moment';
import { EMPLOYMENT_TYPE, SALARY_TYPE } from './constants/roles';

export const getGoogleSalaryType = (salaryType: SALARY_TYPE): string => {
  switch (salaryType) {
    case SALARY_TYPE.hourly:
      return 'HOUR';
    case SALARY_TYPE.weekly:
      return 'WEEK';
    case SALARY_TYPE.monthly:
      return 'MONTH';
    case SALARY_TYPE.yearly:
      return 'YEAR';
    case SALARY_TYPE.daily:
      return 'DAY';
    default:
      return 'UNKNOWN';
  }
};

export const getGoogleEmploymentType = (employmentType: EMPLOYMENT_TYPE): string => {
  return employmentType === EMPLOYMENT_TYPE.fullTime ? 'FULL_TIME' : 'PART_TIME';
};

interface JobPostingStructuredData {
  '@context': string;
  '@type': string;
  title: string;
  datePosted: string;
  description: string;
  hiringOrganization: {
    '@type': string;
    name: string;
  };
  employmentType: string;
  validThrough: string;
  baseSalary: {
    '@type': string;
    currency: string;
    value: {
      '@type': string;
      minValue: number;
      maxValue: number;
      unitText: string;
    };
  };
  jobLocation: {
    '@type': string;
    address: {
      '@type': string;
      addressCountry: string;
    };
  };
}

export const getJobPostingStructuredData = (job: JobviewJob): JobPostingStructuredData => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    title: job.title,
    datePosted: job.date_posted ? moment(job.date_posted).format('YYYY-MM-DD') : '',
    description: job.description,
    hiringOrganization: {
      '@type': 'Organization',
      name: job.schoolroles?.length === 1 ? job.schoolroles[0].school.name : job.district.name,
    },
    employmentType: getGoogleEmploymentType(job.fulltime),
    validThrough: job.deadline ? moment(job.deadline).format('YYYY-MM-DD') : '',
    baseSalary: {
      '@type': 'MonetaryAmount',
      currency: 'USD',
      value: {
        '@type': 'QuantitativeValue',
        minValue: job.salary_min,
        maxValue: job.salary_max,
        unitText: getGoogleSalaryType(job.salary_type),
      },
    },
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        addressCountry: 'US',
      },
    },
  };
};
