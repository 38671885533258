import { styled, Theme, Typography } from '@mui/material';
import { Alert } from 'sharedComponents/Alert';
import { useTheme } from '@mui/material';

export const PreferencesAlertMessage: React.FC = () => {
  const theme = useTheme();
  const today = new Date();
  const startDate = new Date('2025-02-01');

  return (
    <Alert sx={AlertStyles(theme)} icon={false}>
      <Title>Opt into matching and let the principals come to you!</Title>
      <BodyMessage>
        Set your job preferences &amp; we&apos;ll match you with select principals - for free.
      </BodyMessage>
      {today < startDate && <StartDateMessage>Hurry! Matching starts Feb 1</StartDateMessage>}
    </Alert>
  );
};

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.medium,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '26px',
  letterSpacing: '-0.32px',
  textAlign: 'center',
}));

const BodyMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.medium,
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '26px',
  letterSpacing: '-0.28px',
  textAlign: 'center',
}));

const StartDateMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.medium,
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '26px',
  letterSpacing: '-0.28px',
  textAlign: 'center',
}));

const AlertStyles = (theme: Theme) => ({
  borderAlignment: 'inside',
  borderRadius: theme.shape.borderRadius * 0.5,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  gap: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifySelf: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    margin: `0 ${theme.spacing(2)}`,
    gap: theme.spacing(2),
  },
});
