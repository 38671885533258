export const GRADE_MAPPING = {
  early_childhood: [0],
  elementary: [13, 1, 2, 3, 4, 5],
  middle_school: [6, 7, 8],
  high_school: [9, 10, 11, 12],
};

export enum EMPLOYMENT_TYPE {
  partTime = 0.5,
  fullAndPartTime = 0.75,
  fullTime = 1,
}

export enum SALARY_TYPE {
  hourly = 1,
  weekly = 2,
  monthly = 3,
  yearly = 4,
  daily = 5,
}

export enum QuestionType {
  direction_text = 'question_type/direction_text',
  open_response = 'question_type/open_response',
  yes_no = 'question_type/yes_no',
  multiple_choice = 'question_type/multiple_choice',
  nimble = 'question_type/nimble',
  attachment = 'question_type/attachment',
  videoLink = 'question_type/video_link',
  schoolPreferences = 'question_type/school_preferences',
  statementCheckbox = 'question_type/statement_checkbox',
  text_question_model = 'question_type/legacy_text_question',
}

export enum ScorecardQuestionType {
  rating = 0,
  yes_no = 1,
  text = 2,
  nimble = 3,
  multiple_choice = 4,
  direction_text = 5,
  rubric = 6,
  final_recommendation = 7,
  cumulative_score = 8,
  attachment = 9,
}
