import { AxiosResponse } from 'axios';
import { School, District } from 'types/connectTypes.js';
import { BaseNimbleAPI } from '../../../api/baseAPI.js';

interface GetSchoolsByNcesIdRequest {
  stateCode: string;
  ncesId: string;
}

class MarketplaceSchoolsAPI extends BaseNimbleAPI {
  get config(): { baseURL: string } {
    return { baseURL: '/api/marketplace/' };
  }

  async getSchoolFilterOptions({ stateCode }: { stateCode: string }): Promise<School[]> {
    const { data }: AxiosResponse = await this.get('list/schools/', {
      params: { state_abbreviation: stateCode },
    });

    return data;
  }

  async getDistrictFilterOptions({ stateCode }: { stateCode: string }): Promise<District[]> {
    const { data }: AxiosResponse = await this.get('list/districts/', {
      params: { state_abbreviation: stateCode },
    });

    return data;
  }

  async getSchoolByNcesId({
    stateCode,
    ncesId,
  }: GetSchoolsByNcesIdRequest): Promise<School | null> {
    if (!stateCode || !ncesId) throw new Error('State ID and NCES ID are required');

    const queryParams: {
      state_abbreviation: string;
      school_nces_ids: string[];
    } = {
      state_abbreviation: stateCode,
      school_nces_ids: [ncesId],
    };

    const { data, status }: AxiosResponse = await this.get('schools', {
      params: queryParams,
    });
    if (status !== 200) throw new Error('Failed to get schools by NCES ID');

    const school = data?.items.length > 0 ? data.items[0] : null;
    return school;
  }
}

export default new MarketplaceSchoolsAPI();
