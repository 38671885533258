import { useEffect, useReducer } from 'react';

import { useLocation } from 'react-router';

import { Box } from '@mui/material';
// TODO: Comment back in when we are ready to use State-specific themes
// import { ThemeProvider } from '@mui/material';
import { getTheme } from 'stateThemes';
import { styled } from '@mui/material/styles';
import MarketplaceSchoolsAPI from 'features/Connect/api/marketplaceSchoolsAPI';
import { MoreInfoSlider } from 'features/Connect/components/MoreInfoSlider/MoreInfoSlider';
import { ExpressInterestModal } from 'features/Connect/features/ExpressInterest/ExpressInterestModal';
import { useConnectDiscovery } from 'hooks/data/useConnectDiscovery';
import { useConnectFilterOptions } from 'hooks/data/useConnectFilterOptions';
import { ConnectLocationFilterInterface, School } from 'types/connectTypes';
import { JobDiscoveryFilterContainer } from './JobDiscoveryFilters';
import { JobDiscoverySchoolsList } from './JobDiscoverySchoolsList';
import { initialState, reducer } from './utils';

import { ConnectGatedActions } from 'features/Connect/utils/connectEnums';
import { ExpressInterestExitModal } from '../ExpressInterest/ExpressInterestExitModal';
import { ActionTypes, ConnectFilterName } from 'features/Connect/utils/connectEnums';
import auth from 'utils/auth';
import { ConnectMoreInfoSliderDataTestIds } from 'data-testids/ConnectDataTestIds';
import { useStateParam } from '../ConnectStateCodeContextProvider';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';
import { Header } from './JobDiscoveryHeader';
import { StateJobBoardTitle } from 'features/Connect/components/StateJobboardComponents/StateJobBoardTitle';

export const JobDiscovery: React.FC = () => {
  const jobboardState = useStateParam();
  const stateCode =
    CONNECT_JOBBOARD_STATES[jobboardState]?.stateCode || CONNECT_JOBBOARD_STATES.MISSOURI.stateCode;
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const selectedSchoolId = searchParams.get('school');
  const gatedAction = searchParams.get('action');
  const vacancyId = searchParams.get('vacancy');
  const authenticatedUser = auth.getUser();
  const connectTheme = getTheme(jobboardState);

  const [state, dispatch] = useReducer(reducer, initialState);

  const filterValues = {
    ...state,
    state_abbreviation: stateCode,
  };

  const filterOptions = useConnectFilterOptions();

  const actions = {
    setPage: (page: number) =>
      dispatch({
        type: ActionTypes.SET_PAGE,
        payload: page,
      }),
    setQuery: (query: string) =>
      dispatch({
        type: ActionTypes.SET_QUERY,
        payload: query,
      }),
    setSelectedSubjectIds: (ids: number[]) => {
      dispatch({
        type: ActionTypes.SET_FILTER,
        filterName: ConnectFilterName.SUBCATEGORIES,
        payload: ids,
      });
    },
    setSelectedDistance: (distance: string) => {
      dispatch({
        type: ActionTypes.SET_FILTER,
        filterName: ConnectFilterName.DISTANCE_RADIUS_MILES,
        payload: distance,
      });
    },
    setSelectedLocation: (locationFilters: ConnectLocationFilterInterface) => {
      dispatch({
        type: ActionTypes.SET_FILTER,
        filterName: ConnectFilterName.LOCATION,
        payload: locationFilters,
      });
    },
    setSelectedGrades: (grades: number[]) =>
      dispatch({
        type: ActionTypes.SET_FILTER,
        filterName: ConnectFilterName.GRADES,
        payload: grades,
      }),
    setSelectedSchoolIds: (ids: number[]) =>
      dispatch({
        type: ActionTypes.SET_FILTER,
        filterName: ConnectFilterName.SCHOOLS,
        payload: ids,
      }),
    setSelectedDistrictIds: (ids: number[]) =>
      dispatch({
        type: ActionTypes.SET_FILTER,
        filterName: ConnectFilterName.DISTRICTS,
        payload: ids,
      }),
    setFilterIsActive: (filterName: string, isActive: boolean) =>
      dispatch({
        type: ActionTypes.SET_FILTER_IS_ACTIVE,
        filterName,
        payload: isActive,
      }),
    setIsLoading: (isLoading: boolean) =>
      dispatch({
        type: ActionTypes.SET_IS_LOADING,
        payload: isLoading,
      }),
    setUseDebounce: (useDebounce: boolean) =>
      dispatch({
        type: ActionTypes.SET_USE_DEBOUNCE,
        payload: useDebounce,
      }),
    setExpressInterestModalIsOpen: (expressInterestModalIsOpen: boolean) => {
      if (expressInterestModalIsOpen) {
        dispatch({
          type: ActionTypes.SET_EXPRESS_INTEREST_EXIT_MODAL_IS_OPEN,
          payload: false,
        });
      }
      dispatch({
        type: ActionTypes.SET_EXPRESS_INTEREST_MODAL_IS_OPEN,
        payload: expressInterestModalIsOpen,
      });
    },
    setExpressInterestExitModalIsOpen: (expressInterestExitModalIsOpen: boolean) => {
      if (expressInterestExitModalIsOpen) {
        dispatch({
          type: ActionTypes.SET_EXPRESS_INTEREST_MODAL_IS_OPEN,
          payload: false,
        });
      }
      dispatch({
        type: ActionTypes.SET_EXPRESS_INTEREST_EXIT_MODAL_IS_OPEN,
        payload: expressInterestExitModalIsOpen,
      });
    },
    setSelectedSchool: (selectedSchool: School) =>
      dispatch({
        type: ActionTypes.SET_SELECTED_SCHOOL,
        payload: selectedSchool,
      }),
    setMoreInfoSliderIsOpen: (moreInfoSliderIsOpen: boolean) =>
      dispatch({
        type: ActionTypes.SET_MORE_INFO_SLIDER_IS_OPEN,
        payload: moreInfoSliderIsOpen,
      }),
    setSelectedVacancyId: (selectedVacancyId: string) =>
      dispatch({
        type: ActionTypes.SET_SELECTED_VACANCY_ID,
        payload: selectedVacancyId,
      }),
    setSchools: (schools: School[]) =>
      dispatch({
        type: ActionTypes.SET_SCHOOLS,
        payload: schools,
      }),
    setReadyToConnectModalIsOpen: (readyToConnectModalIsOpen: boolean) =>
      dispatch({
        type: ActionTypes.SET_READY_TO_CONNECT_MODAL_IS_OPEN,
        payload: readyToConnectModalIsOpen,
      }),
    setContactedSchoolIds: (schoolId: string) =>
      dispatch({
        type: ActionTypes.SET_CONTACTED_SCHOOL_IDS,
        payload: schoolId,
      }),
    setIsLoadingInitialFilterResults: (isLoadingInitialFilterResults: boolean) =>
      dispatch({
        type: ActionTypes.SET_IS_LOADING_INITIAL_FILTER_RESULTS,
        payload: isLoadingInitialFilterResults,
      }),
    hasSchoolBeenContacted: (schoolId: string): boolean => {
      return !!state.contactedSchoolIds[schoolId];
    },
    toggleSavedSchoolIds: (schoolId: string) =>
      dispatch({
        type: ActionTypes.TOGGLE_SAVED_SCHOOL_IDS,
        payload: schoolId,
      }),
    hasSchoolBeenSaved: (schoolId: string): boolean => {
      return !!state.savedSchoolIds[schoolId];
    },
    setIsUserAuthenticated: (userIsAuthenticated: boolean) =>
      dispatch({
        type: ActionTypes.SET_IS_USER_AUTHENTICATED,
        payload: userIsAuthenticated,
      }),
  };

  const { results, hasMore, count, isFetchingNextPage, setIsFetchingNextPage } =
    useConnectDiscovery(
      { ...filterValues },
      state.activeFilters,
      actions.setIsLoading,
      actions.setIsLoadingInitialFilterResults,
      state.useDebounce
    );

  const handleMoreInfoSliderOpen = () => {
    actions.setMoreInfoSliderIsOpen(true);
    actions.setExpressInterestModalIsOpen(false);
  };

  const handleMoreInfoSliderClose = () => {
    actions.setMoreInfoSliderIsOpen(false);
    actions.setSelectedSchool(null);
    actions.setSelectedVacancyId('0');
  };

  const handleUpdateContactedSchools = (contactedSchool: School) => {
    const updatedSchools = state.schools.map((school) => {
      if (school.nces_id === contactedSchool.nces_id) {
        return {
          ...school,
          hasExpressedInterest: true,
        };
      }
      return school;
    });
    actions.setSchools(updatedSchools);
  };

  useEffect(() => {
    const fetchSchoolData = async () => {
      actions.setIsLoading(true);
      try {
        const school = await MarketplaceSchoolsAPI.getSchoolByNcesId({
          stateCode: stateCode,
          ncesId: selectedSchoolId,
        });
        actions.setSelectedSchool(school);
        if (vacancyId) {
          actions.setSelectedVacancyId(vacancyId);
          actions.setMoreInfoSliderIsOpen(true);
          actions.setReadyToConnectModalIsOpen(true);
        } else if (gatedAction === ConnectGatedActions.EXPRESS_INTEREST) {
          actions.setExpressInterestModalIsOpen(true);
        }
      } catch (error) {
        console.error(error);
      } finally {
        actions.setIsLoading(false);
      }
    };

    if (results && selectedSchoolId && !state.selectedSchool) {
      fetchSchoolData();
    }
    actions.setSchools(results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, selectedSchoolId, vacancyId]);

  useEffect(() => {
    if (authenticatedUser) {
      actions.setIsUserAuthenticated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // TODO: Comment back in when we are ready to use State-specific themes
    // <ThemeProvider theme={connectTheme}>
    <ConnectJobboardContainer>
      <Header isUserAuthenticated={state.isUserAuthenticated} />
      <JobDiscoveryContainer>
        {state.expressInterestModalIsOpen && (
          <ExpressInterestModal
            school={state.selectedSchool}
            updateContactedSchools={handleUpdateContactedSchools}
            expressInterestModalIsOpen={state.expressInterestModalIsOpen}
            actions={actions}
          />
        )}
        {state.expressInterestExitModalIsOpen && (
          <ExpressInterestExitModal
            isOpen={state.expressInterestExitModalIsOpen}
            actions={actions}
          />
        )}
        {state.moreInfoSliderIsOpen && (
          <MoreInfoSlider
            closeHandler={handleMoreInfoSliderClose}
            openHandler={handleMoreInfoSliderOpen}
            isOpen={state.moreInfoSliderIsOpen}
            isReadyToConnectModalOpen={state.readyToConnectModalIsOpen}
            anchor={'right'}
            actions={actions}
            school={state.selectedSchool}
            vacancyId={state.selectedVacancyId}
            dataTestId={ConnectMoreInfoSliderDataTestIds.MORE_INFO_SLIDER}
          />
        )}
        <Box maxWidth={'1130px'} width={'100%'}>
          <StateJobBoardTitle sx={JobboardTitleStyles(connectTheme)} titleTextSize="" />
          <JobDiscoveryFilterContainer
            filterValues={filterValues}
            actions={actions}
            filterOptions={filterOptions}
            isUserAuthenticated={state.isUserAuthenticated}
            isLoading={state.isLoading}
            activeFilters={state.activeFilters}
          />
        </Box>
        <Box maxWidth={'1130px'} width={'100%'}>
          <JobDiscoverySchoolsList
            schools={state.schools}
            dispatch={dispatch}
            hasMore={hasMore}
            count={count}
            page={state.page}
            isLoading={state.isLoading}
            isFetchingNextPage={isFetchingNextPage}
            isLoadingInitialFilterResults={state.isLoadingInitialFilterResults}
            setIsFetchingNextPage={setIsFetchingNextPage}
            actions={actions}
          />
        </Box>
      </JobDiscoveryContainer>
    </ConnectJobboardContainer>
    // </ThemeProvider>
  );
};

const ConnectJobboardContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(10),
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(7),
  },
}));

const JobDiscoveryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
  flexDirection: 'column',
  alignItems: 'center',
  background: theme.palette.white.main,

  [theme.breakpoints.down('sm')]: {
    padding: '0px 16px 20px',
  },
}));

const JobboardTitleStyles = (theme) => ({
  display: { xs: 'flex', md: 'none' },
  alignSelf: 'center',
  textAlign: 'center',
  gap: 1,
  margin: `${theme.spacing(4)} ${theme.spacing(5)}`,
});
