import { Box, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SchoolCard } from 'features/Connect/components/SchoolCard';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { School } from 'types/connectTypes';
import { SchoolsListProps } from 'types/connectTypes';
import auth from 'utils/auth';
import { ActionTypes } from 'features/Connect/utils/connectEnums';
import { NoResultsFoundCard } from './NoResultsFoundCard';
import { EndOfResultsCard } from './EndOfResultsCard';
import { GatedResultsCard } from './GatedResultsCard';

export const JobDiscoverySchoolsList: React.FC<SchoolsListProps> = ({
  schools,
  page,
  isLoading,
  isFetchingNextPage,
  isLoadingInitialFilterResults,
  setIsFetchingNextPage,
  dispatch,
  hasMore,
  actions,
  count,
}) => {
  const generateNearbySchoolsText = (schools: School[]) => {
    if (!schools) {
      return '';
    } else if (count === 1) {
      return 'Showing 1 nearby school that matches your criteria';
    } else {
      return `Showing ${count} nearby schools matching your criteria`;
    }
  };

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasMore && !isFetchingNextPage) {
      setIsFetchingNextPage(true);
      dispatch({ type: ActionTypes.SET_PAGE, payload: page + 1 });
    }
  }, [inView, hasMore, isFetchingNextPage, dispatch, page, setIsFetchingNextPage]);

  const isSearchResultsFeatureFlagEnabled = auth.hasConnectSearchResults();
  const schoolsToRender = isSearchResultsFeatureFlagEnabled ? schools.slice(0, 5) : schools;

  const renderLoadingSpinner = () => (
    <div style={{ textAlign: 'center', paddingTop: 24 }}>
      <CircularProgress />
    </div>
  );

  const renderSchoolsList = () => (
    <>
      {schoolsToRender.map((school) => (
        <SchoolCard key={school.nces_id} school={school} actions={actions} />
      ))}
      <div id="load-more-results-trigger" ref={ref} />
      {isFetchingNextPage && renderLoadingSpinner()}
      {!hasMore && <EndOfResultsCard />}
      {isSearchResultsFeatureFlagEnabled && (
        <GatedResultsCard count={count} numberOfSchoolsRendered={schoolsToRender.length} />
      )}
    </>
  );

  const isInitialLoading = isLoading && !isFetchingNextPage;

  return (
    <SchoolListContainer width={'100%'}>
      <SchoolsFoundSubHeader>{generateNearbySchoolsText(schools)}</SchoolsFoundSubHeader>

      {isInitialLoading && renderLoadingSpinner()}

      {!isInitialLoading &&
        !isLoadingInitialFilterResults &&
        schools?.length > 0 &&
        renderSchoolsList()}

      {!isInitialLoading && !isLoadingInitialFilterResults && !schools?.length && (
        <NoResultsFoundCard />
      )}
    </SchoolListContainer>
  );
};

const SchoolListContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 1,
  width: '100%',
  justifyItems: 'center',
  justifyContent: 'space-evenly',
}));

const SchoolsFoundSubHeader = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600',
  fontStyle: 'normal',
  lineHeight: '28px',
  padding: '12px 0px 24px',
}));
