import styled from 'styled-components';
import { layout, type LayoutProps } from 'styled-system';

import ScorecardQuestionRow from './ScorecardQuestionRow';
import QuestionBox from '../common/QuestionBox';

import grayArrow from '../../assets/icon_right_caret_grey.svg';
import { ReactComponent as RightArrow } from 'assets/icon_right_caret_grey.svg';

import {
  scorecardQuestionOptions,
  scorecardPermissionsSuper,
  scorecardPermissionsSchool,
} from '../../utils/enums';
import auth from 'utils/auth';

import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
  DropResult,
} from 'react-beautiful-dnd';
import { ScorecardQuestion } from 'containers/types';
import { DraggableQuestionsProps, ScorecardEditProps } from './types';
import { ScorecardQuestionType } from 'utils/constants';

const DraggableQuestions: React.FC<DraggableQuestionsProps> = ({
  scorecard,
  setQuestionOrder,
  saveQuestion,
  deleteQuestion,
  questionBank,
}) => {
  const reorder = (
    list: ScorecardQuestion[],
    startIndex: number,
    endIndex: number
  ): ScorecardQuestion[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (
    _: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle
  ): { [k in string]: string | number } => ({
    userSelect: 'none',
    padding: '8px 0',
    ...draggableStyle,
  });

  const onDragEnd = (result: DropResult): void => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      scorecard.questions,
      result.source.index,
      result.destination.index
    );

    setQuestionOrder(reorderedItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {scorecard?.questions.map((item, index) => (
              <Draggable
                key={item.draggable_id}
                draggableId={String(item.draggable_id)}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <ScorecardQuestionRow
                      id={index}
                      key={item.draggable_id}
                      question={item}
                      saveQuestion={saveQuestion}
                      deleteQuestion={deleteQuestion}
                      questionBank={questionBank}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const ScorecardEdit: React.FC<ScorecardEditProps> = ({
  scorecard,
  setQuestionOrder,
  openNewQuestionModal,
  openCopyScorecardModal,
  saveScorecard,
  deleteScorecard,
  cancelChanges,
  updateField,
  saveQuestion,
  deleteQuestion,
  questionBank,
  errorMessage,
}) => {
  const getScorecardPermissions = (): { value: string; label: string }[] | null => {
    if (auth.isSuperAdminUser()) {
      return scorecardPermissionsSuper;
    } else if (auth.isSchoolAdmin()) {
      return scorecardPermissionsSchool;
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="edit-scorecard-header flex">
        <div className="scorecard-back-button" onClick={cancelChanges}>
          <img src={grayArrow} alt="" />
          <span className="Scorecard">Return to settings page</span>
        </div>
        <h3>{scorecard.id === undefined ? 'Design New Scorecard' : 'Edit Scorecard'}</h3>
      </div>
      <div className="jobedit-container-padding">
        <HeaderText>Scorecard Name</HeaderText>
        <ScorecardNameInput
          name="title"
          type="text"
          placeholder="eg. Teacher Default Scorecard"
          value={scorecard.title}
          onChange={updateField}
        />
        <HeaderText>Scorecard Items</HeaderText>
        {scorecard.questions.length === 0 ? (
          <GreyText>
            Select an item below to start building or
            <WorkFromExisting onClick={openCopyScorecardModal}>
              work from an existing scorecard
            </WorkFromExisting>
            <RightCaret />
          </GreyText>
        ) : (
          <DraggableQuestions
            scorecard={scorecard}
            setQuestionOrder={setQuestionOrder}
            saveQuestion={saveQuestion}
            deleteQuestion={deleteQuestion}
            questionBank={questionBank}
          />
        )}
        {(auth.isSchoolAdmin() || auth.isSuperAdminUser()) && (
          <>
            <HeaderText>Permissions</HeaderText>
            <GreyText>
              <span>Who can use this scorecard template:</span>
              <VisibilityDropdown
                name="view_permissions"
                value={scorecard.view_permissions}
                onChange={updateField}
              >
                {getScorecardPermissions().map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </VisibilityDropdown>
            </GreyText>
          </>
        )}
        <HeaderText>Add Items</HeaderText>
        <div className="question-type-container mb4">
          {scorecardQuestionOptions().map((item) => (
            <QuestionBox
              key={item.key}
              handleClick={(type) => openNewQuestionModal(type as ScorecardQuestionType)}
              label={item.label}
              type={item.value}
            />
          ))}
        </div>
        <ActionButtonsContainer>
          {scorecard.id && (
            <DeleteButton
              onClick={deleteScorecard}
              width={[1, 1, '150px']}
              minWidth={[1, 1, '150px']}
            >
              Delete form
            </DeleteButton>
          )}
          <SubmitButtonContainer>
            {errorMessage && <ErrorTextStyled>{errorMessage}</ErrorTextStyled>}
            <SaveButton onClick={saveScorecard} width={[1, 1, '150px']} minWidth={[1, 1, '150px']}>
              Save form
            </SaveButton>
          </SubmitButtonContainer>
        </ActionButtonsContainer>
      </div>
    </>
  );
};

export default ScorecardEdit;
interface ActionButtonProps extends LayoutProps {
  onClick: () => void;
  children?: React.ReactNode;
}

const ScorecardNameInput = styled.input`
  background-color: #fff;
  padding: 20px;
  border: 0;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  height: 50px;
  width: 100%;

  &::placeholder {
    color: #999999;
    opacity: 0.6;
  }
`;

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #444444;

  margin-bottom: 8px;
  margin-top: 28px;
`;

const GreyText = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #999999;
`;

const WorkFromExisting = styled.u`
  cursor: pointer;
  margin: 0 4px;

  &:hover {
    color: #00b88d;
  }
`;

const RightCaret = styled(RightArrow)`
  height: 10px;
`;

const ActionButtonsContainer = styled.div`
  display: grid;
  grid-row-gap: 12px;

  margin-top: 80px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr minmax(258px, 1fr);
    grid-column-gap: 12px;
  }
`;

const ActionButton = styled.button<ActionButtonProps>`
  ${layout}

  height: 50px !important;
  box-shadow: none !important;
  font-size: 16px;
  border-radius: 3px;
`;

const DeleteButton = styled(ActionButton)`
  color: #ef5675 !important;
  background-color: #ffffff !important;
  border: 1px solid #ef5675 !important;
`;

const SaveButton = styled(ActionButton)`
  color: white !important;
  background-color: #00b88d !important;
  border: none !important;
`;

const SubmitButtonContainer = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-self: end;
    grid-column-start: 2;
  }
`;

const VisibilityDropdown = styled.select`
  background-color: white;
  border: none;
  padding: 5px 3px;
  margin-left: 6px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.0954484);
  border-radius: 3px;
`;

const ErrorTextStyled = styled.p`
  color: #ff7700;
  min-height: 1.5rem;
  margin-right: 1rem;
`;
