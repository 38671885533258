import styled from 'styled-components';
import { MarketPlaceFitBadgeProps } from '../types/types';
import { MarketplaceFitStatus } from 'utils/constants';

const MarketPlaceFitBadge: React.FC<MarketPlaceFitBadgeProps> = ({ status }) => {
  const getBadgeByStatus = () => {
    if (status === MarketplaceFitStatus.NO_FIT) {
      return <NotFitStatus>Not a Fit</NotFitStatus>;
    } else if (status === MarketplaceFitStatus.POTENTIAL_FIT) {
      return <PotentialFitStatus>Potential Fit</PotentialFitStatus>;
    } else if (status === MarketplaceFitStatus.CONVERTED_TO_PROSPECT) {
      return <ConvertedToProspectStatus>Converted to Prospect</ConvertedToProspectStatus>;
    } else {
      return <BaseStatus>Not Reviewed</BaseStatus>;
    }
  };
  return <div>{getBadgeByStatus()}</div>;
};

const BaseStatus = styled.div`
  display: inline-block;
  padding: 4.5px 8px;
  height: 28px;
  background: #828282;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
`;

const PotentialFitStatus = styled(BaseStatus)`
  color: #e46b00;
  border: 1px solid #e46b00;
  background-color: rgba(228, 107, 0, 0.08);
`;
const NotFitStatus = styled(BaseStatus)`
  color: #cc0000;
  border: 1px solid #cc0000;
  background-color: rgba(204, 0, 0, 0.08);
`;
const ConvertedToProspectStatus = styled(BaseStatus)`
  color: #00a37d;
  border: 1px solid #00a37d;
  background-color: rgba(0, 163, 125, 0.08);
`;
export default MarketPlaceFitBadge;
