import styled from 'styled-components';
import { MarketPlaceFitButtonsProps, StyledIconButtonProps } from '../types/types';

import PotentialFitIcon from '../../../MarketplaceList/assets/maybe.svg';
import NotAFitIcon from '../../../MarketplaceList/assets/no.svg';
import ConvertToProspectIcon from '../../../MarketplaceList/assets/yes.svg';
import PotentialFitSelectedIcon from '../../../MarketplaceList/assets/maybeSelected.svg';
import NotAFitSelectedIcon from '../../../MarketplaceList/assets/noSelected.svg';
import ConvertToProspectSelectedIcon from '../../../MarketplaceList/assets/yesSelected.svg';
import { MarketplaceFitStatus } from 'utils/constants';

const MarketPlaceFitButtons: React.FC<MarketPlaceFitButtonsProps> = ({
  selectedButton,
  onMarketplaceStatusUpdate,
}) => {
  const isDisabled = (currentButton: MarketplaceFitStatus) =>
    selectedButton === 'converted_to_prospect' || selectedButton === currentButton;

  const fitData: {
    [key: string]: {
      default: string;
      bordered: string;
      enabledIcon: string;
      disabledIcon: string;
      color: string;
    };
  } = {
    [MarketplaceFitStatus.NO_FIT]: {
      default: 'no-fit',
      bordered: 'no-fit-bordered',
      enabledIcon: NotAFitIcon,
      disabledIcon: NotAFitSelectedIcon,
      color: '#cc0000',
    },
    [MarketplaceFitStatus.POTENTIAL_FIT]: {
      default: 'potential-fit',
      bordered: 'potential-fit-bordered',
      enabledIcon: PotentialFitIcon,
      disabledIcon: PotentialFitSelectedIcon,
      color: '#e46b00',
    },
    [MarketplaceFitStatus.CONVERTED_TO_PROSPECT]: {
      default: 'convert-to-prospect',
      bordered: 'convert-to-prospect-bordered',
      enabledIcon: ConvertToProspectIcon,
      disabledIcon: ConvertToProspectSelectedIcon,
      color: '#00A37D',
    },
  };

  const CircleFitButton = ({ currentButton }: { currentButton: MarketplaceFitStatus }) => {
    return (
      <StyledCombinedFitIcon
        alt={fitData[currentButton].bordered}
        src={
          isDisabled(currentButton)
            ? fitData[currentButton].disabledIcon
            : fitData[currentButton].enabledIcon
        }
        onClick={() => !isDisabled(currentButton) && onMarketplaceStatusUpdate(currentButton)}
        isSelected={selectedButton === currentButton}
        isDisabled={isDisabled(currentButton)}
        color={fitData[currentButton].color}
      />
    );
  };
  return (
    <Container>
      {selectedButton ? (
        <CircleButtonContainer>
          <CircleFitButton currentButton={MarketplaceFitStatus.NO_FIT} />
          <CircleFitButton currentButton={MarketplaceFitStatus.POTENTIAL_FIT} />
          <CircleFitButton currentButton={MarketplaceFitStatus.CONVERTED_TO_PROSPECT} />
        </CircleButtonContainer>
      ) : (
        <SquareButtonContainer>
          <NotAFitButton onClick={() => onMarketplaceStatusUpdate(MarketplaceFitStatus.NO_FIT)}>
            <StyledIcon alt="no-fit" src={NotAFitIcon} />
            Not a Fit
          </NotAFitButton>
          <PotentialFitButton
            onClick={() => onMarketplaceStatusUpdate(MarketplaceFitStatus.POTENTIAL_FIT)}
          >
            <StyledIcon alt="potential-fit" src={PotentialFitIcon} />
            Potential Fit
          </PotentialFitButton>
          <ConvertToProspectButton
            onClick={() => onMarketplaceStatusUpdate(MarketplaceFitStatus.CONVERTED_TO_PROSPECT)}
          >
            <StyledIcon src={ConvertToProspectIcon} alt="convert-to-prospect" />
            Convert To Prospect
          </ConvertToProspectButton>
        </SquareButtonContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding-right: 32px;
`;

const SquareButtonContainer = styled.div`
  display: flex;
  justify-content: start;
  gap: 16px;
  @media (max-width: 1165px) {
    flex-direction: column;
  }
`;

const CircleButtonContainer = styled.div``;

const FitButton = styled.div`
  line-height: 24px;
  weight: 600;
  size: 15px;
  height: 40px;
  radius: 2px;
  border: 1px solid;
  border-radius: 2px;
  padding: 8px 16px;
`;
const NotAFitButton = styled(FitButton)`
  color: #cc0000;
  border-color: #cc0000;
  &:hover {
    background-color: rgba(204, 0, 0, 0.08);
  }
`;
const PotentialFitButton = styled(FitButton)`
  color: #e46b00;
  border-color: #e46b00;
  &:hover {
    background-color: rgba(228, 107, 0, 0.08);
  }
`;
const ConvertToProspectButton = styled(FitButton)`
  color: #00a37d;
  border-color: #00a37d;
  &:hover {
    background-color: rgba(0, 163, 125, 0.08);
  }
`;

const StyledIcon = styled.img`
  padding-bottom: 2px;
  margin-right: 8px;
  width: 12px;
  height: 12px;
`;

const StyledIconButton = styled(StyledIcon)<StyledIconButtonProps>`
  border: 1px solid;
  fill: ${({ isSelected }) => (isSelected ? '#BDBDBD !important' : '')};
  border-color: ${({ isSelected }) => (isSelected ? '#BDBDBD !important' : '')};
  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? 'rgba(189, 189, 189, 0.08) !important' : ''};
  }
`;

const StyledCombinedFitIcon = styled(StyledIconButton)<StyledIconButtonProps>`
  width: 32px;
  height: 32px;
  padding: 10px;
  color: ${({ color }) => color || '#e46b00'};
  border-color: ${({ color, isDisabled }) => (isDisabled ? '#BDBDBD' : color || '#e46b00')};
  border-radius: 50%;
  &:hover {
    background-color: ${({ isDisabled }) => (isDisabled ? '' : 'rgba(228, 107, 0, 0.08)')};
  }
`;

export default MarketPlaceFitButtons;
