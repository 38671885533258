export enum ConnectActionButtonsDataTestIds {
  EXPRESS_INTEREST_BUTTON = 'express-interest-button',
  SAVE_SCHOOL_BUTTON = 'connect-save-school-button',
}

export enum ConnectAlertsBannerDataTestIds {
  NOT_SUBSCRIBED_BANNER = 'connect-alerts-banner-not-subscribed',
  SUBSCRIBED_BANNER = 'connect-alerts-banner-subscribed',
}

export enum ConnectCandidatePreferencesDataTestIds {
  COMPLETE_PROFILE_TEXT = 'connect-candidate-profile-complete-profile-title-text',
}

export enum ConnectJobDiscoveryFiltersDataTestIds {
  DISTANCE_FILTER = 'connect-job-discovery-distance-filter-dropdown',
  DISTRICT_FILTER = 'connect-job-discovery-district-filter-dropdown',
  GRADE_FILTER = 'connect-job-discovery-grade-level-filter',
  LOCATION_INPUT = 'connect-job-discovery-location-filter-input',
  SCHOOL_SEARCH_FILTER = 'connect-job-discovery-school-search-filter',
  SUBJECTS_FILTER = 'connect-job-discovery-subject-filter',
  UPDATE_DISTANCE_FROM_LOCATION_SUBMIT_BUTTON = 'connect-job-discovery-location-filter-submit-button',
}

export enum ConnectJobDiscoveryGatedResultsCardDataTestIds {
  GATED_RESULTS_CARD = 'connect-job-discovery-gated-results-card-container',
  HEADER_TEXT = 'connect-job-discovery-gated-results-card-header-text',
  SIGNUP_BUTTON = 'gated-results-card-signup-button',
}

export enum ConnectJobDiscoverySchoolCardDataTestIds {
  SCHOOL_CARD = 'connect-job-discovery-school-card-container',
}

export enum ConnectLazyRegistrationLoginSignUpPageDataTestIds {
  EMAIL_INPUT = 'connect-lazy-registration-login-sign-up-page-email-input',
  SUBMIT_BUTTON = 'connect-lazy-registration-login-sign-up-page-submit-button',
}

export enum ConnectMarketingInputAndButtonDataTestIds {
  COMPLETE_PROFILE_BUTTON = 'connect-marketing-banner-complete-profile-button',
  EMAIL_INPUT = 'marketing-email-input-jobs-discovery',
  SIGN_UP_BUTTON = 'marketing-sign-up-button',
}

export enum ConnectMoreInfoSliderDataTestIds {
  MORE_INFO_SLIDER = 'connect-job-discovery-more-info-slider',
}

export enum ConnectValuePropBannerDataTestIds {
  HEADER_TEXT = 'connect-job-discovery-value-prop-banner-header-text',
  SUB_HEADER_TEXT = 'connect-job-discovery-value-prop-banner-sub-header-text',
  SUB_HEADER_LINK_TEXT = 'connect-job-discovery-value-prop-banner-sub-header-link-text',
  IMAGE_ASSET = 'connect-job-discovery-value-prop-banner-image-asset',
}

export enum ConnectProfileFlowDataTestIds {
  CONNECT_ANNOUNCEMENT_BANNER = 'connect-profile-flow-announcement-banner',
  CONNECT_OPPORTUNITY_NO = 'connect-profile-flow-preferences-page-connect-opportunity-no-toggle',
  CONNECT_OPPORTUNITY_QUESTION_LABEL = 'connect-profile-flow-preferences-page-connect-opportunity-question-label',
  CONNECT_OPPORTUNITY_RADIO_GROUP = 'connect-profile-flow-preferences-page-connect-opportunity-radio-group',
  CONNECT_OPPORTUNITY_YES = 'connect-profile-flow-preferences-page-connect-opportunity-yes-toggle',
  DISTANCE_DROPDOWN = 'connect-profile-flow-preferences-page-distance-dropdown',
  DISTANCE_QUESTION_LABEL = 'connect-profile-flow-preferences-page-distance-question-label',
  GRADES_DROPDOWN = 'connect-profile-flow-preferences-page-grades-dropdown',
  GRADES_QUESTION_LABEL = 'connect-profile-flow-preferences-page-grades-question-label',
  JOB_SEEKING_STATUS_NO = 'connect-profile-flow-preferences-page-job-seeking-status-no-toggle',
  JOB_SEEKING_STATUS_QUESTION_LABEL = 'connect-profile-flow-preferences-page-job-seeking-status-question-label',
  JOB_SEEKING_STATUS_RADIO_GROUP = 'connect-profile-flow-preferences-page-job-seeking-status-radio-group',
  JOB_SEEKING_STATUS_YES = 'connect-profile-flow-preferences-page-job-seeking-status-yes-toggle',
  LOCATION_INPUT = 'connect-profile-flow-preferences-page-location-text-field-input',
  LOCATION_QUESTION_LABEL = 'connect-profile-flow-preferences-page-location-question-label',
  PREFERENCES_BACK_BUTTON = 'connect-profile-flow-preferences-page-back-button',
  PREFERENCES_BUTTON_GROUP = 'connect-profile-flow-preferences-page-button-group',
  PREFERENCES_SAVE_AND_CONTINUE_BUTTON = 'connect-profile-flow-preferences-page-save-and-continue-button',
  SCHOOLS_TO_HIDE_FROM_QUESTION_LABEL = 'connect-profile-flow-preferences-page-schools-to-hide-from-question-label',
  SCHOOLS_TO_HIDE_FROM_DROPDOWN = 'connect-profile-flow-preferences-page-schools-to-hide-from-dropdown',
  SUBJECTS_DROPDOWN = 'connect-profile-flow-preferences-page-subjects-dropdown',
  SUBJECTS_QUESTION_LABEL = 'connect-profile-flow-preferences-page-subjects-question-label',
  FIRST_NAME_INPUT = 'connect-profile-flow-create-update-page-first-name-input',
  FIRST_NAME_QUESTION_LABEL = 'connect-profile-flow-create-update-page-first-name-question-label',
  LAST_NAME_INPUT = 'connect-profile-flow-create-update-page-last-name-input',
  LAST_NAME_QUESTION_LABEL = 'connect-profile-flow-create-update-page-last-name-question-label',
  PHONE_NUMBER_INPUT = 'connect-profile-flow-create-update-page-phone-number-input',
  PHONE_NUMBER_QUESTION_LABEL = 'connect-profile-flow-create-update-page-phone-number-question-label',
  STATE_PREFERENCES_DROPDOWN = 'connect-profile-flow-create-update-page-state-preferences-dropdown',
  STATE_PREFERENCES_QUESTION_LABEL = 'connect-profile-flow-create-update-page-state-preferences-question-label',
  CREATE_UPDATE_PROFILE_SAVE_AND_CONTINUE_BUTTON = 'connect-profile-flow-create-update-page-save-and-continue-button',
}
