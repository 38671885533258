import { Box, Divider, styled, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import QuestionsTab from './QuestionsTab';
import VacanciesTab from './VacanciesTab';
import SetupTab from './SetupTab';
import HiringTeamTab from './HiringTeamTab';
import InternalNotesTab from './InternalNotesTab';
import JobPostingTab from './JobPostingTab';
import auth from 'utils/auth';

type JobviewTabData = {
  value: number;
  label:
    | 'Job Posting'
    | 'Questions'
    | 'Vacancies'
    | 'Job Set-up'
    | 'Hiring Team'
    | 'Internal Notes';
  content: React.ReactNode;
  isVisible: boolean;
};

const JobViewTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const tabData: JobviewTabData[] = [
    { value: 0, label: 'Job Posting', content: <JobPostingTab />, isVisible: true },
    { value: 1, label: 'Questions', content: <QuestionsTab />, isVisible: true },
    {
      value: 2,
      label: 'Vacancies',
      content: <VacanciesTab />,
      isVisible: auth.isDistrictAdmin(),
    },
    { value: 3, label: 'Job Set-up', content: <SetupTab />, isVisible: true },
    { value: 4, label: 'Hiring Team', content: <HiringTeamTab />, isVisible: true },
  ];

  const internalNotesTabData: JobviewTabData = {
    value: 5,
    label: 'Internal Notes',
    content: <InternalNotesTab />,
    isVisible: true,
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: number): void => {
    setSelectedTab(newValue);
  };

  return (
    <Container>
      <TabsContainer>
        <Tabs value={selectedTab} onChange={handleTabChange} orientation="vertical">
          {tabData.map((tab) => (
            <CustomTab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              sx={{ display: !tab.isVisible && 'none' }}
            />
          ))}
          <Divider sx={{ display: !internalNotesTabData.isVisible && 'none' }} />
          <CustomTab
            key={internalNotesTabData.value}
            value={internalNotesTabData.value}
            label={internalNotesTabData.label}
            sx={{ display: !internalNotesTabData.isVisible && 'none' }}
          />
        </Tabs>
      </TabsContainer>
      {tabData.map((tab) => (
        <TabContentContainer key={tab.value} display={selectedTab === tab.value ? 'block' : 'none'}>
          {tab.content}
        </TabContentContainer>
      ))}
    </Container>
  );
};
export default JobViewTabs;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(4),
}));

const TabsContainer = styled(Box)(({ theme }) => ({
  width: '137px',
  marginTop: theme.spacing(4),
}));

const TabContentContainer = styled(Box)(() => ({}));

const CustomTab = styled(Tab)(({ theme }) => ({
  alignItems: 'start',
  paddingLeft: theme.spacing(1.5),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMediumBold,
  fontSize: theme.typography.body2.fontSize,
}));
