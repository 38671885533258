import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import QuestionSetContainer from './QuestionSetContainer';
import JobQuestionRow from './JobQuestionRow';
import { Job, Question, QuestionSet } from 'types';
import { QuestionType } from 'utils/constants';

const reorder = (list, startIndex, endIndex): (Question | QuestionSet)[] => {
  const result: (Question | QuestionSet)[] = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (_, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: '8px 0',

  // styles we need to apply on draggables
  ...draggableStyle,
});

export interface QuestionsForCandidateProps {
  job: Job;
  jobQuestionsAndAttachmentsAndSets: any[];
  deleteQuestionRow: (row: string) => void;
  openQuestionModal: (type: QuestionType, obj: Question) => void;
  openQuestionSetModal: (questionSetUUID: string, createRoleQuestionSet?: boolean) => void;
  handleQuestionSetEditOptions: (questionSetUUID: string | null) => void;
  setItemOrder: (s: (Question | QuestionSet)[]) => void;
}

const QuestionsForCandidate: React.FC<QuestionsForCandidateProps> = ({
  job,
  jobQuestionsAndAttachmentsAndSets,
  deleteQuestionRow,
  openQuestionModal,
  openQuestionSetModal,
  handleQuestionSetEditOptions,
  setItemOrder,
}) => {
  const draggableQuestionAndQuestionSets = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {jobQuestionsAndAttachmentsAndSets.map((question, index) => (
              <Draggable
                key={question.localId || question.id}
                draggableId={String(question.localId || question.id)}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    {' '}
                    {question.is_qs ? (
                      <QuestionSetContainer
                        questionSet={question}
                        deleteQuestionRow={() => deleteQuestionRow(question.localId || question.id)}
                        internalRequirementsSpecified={job.internal_requirements_specified}
                        openQuestionSetModal={openQuestionSetModal}
                        isShowingQuestionSetEditOptions={question.isShowingEditOptions}
                        handleQuestionSetEditOptions={handleQuestionSetEditOptions}
                        isTemplate={job.is_template}
                      />
                    ) : (
                      <JobQuestionRow
                        obj={question}
                        type={question.question_type}
                        deleteQuestionRow={() => deleteQuestionRow(question.localId || question.id)}
                        editQuestionRow={() => openQuestionModal(question.question_type, question)}
                        internal_requirements_specified={job.internal_requirements_specified}
                      />
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      jobQuestionsAndAttachmentsAndSets,
      result.source.index,
      result.destination.index
    );

    setItemOrder(reorderedItems);
  };

  return (
    <>
      <Label>Questions for Candidate</Label>
      <div className="form-field jobedit-textarea mb4">
        {jobQuestionsAndAttachmentsAndSets.length > 0 ? (
          draggableQuestionAndQuestionSets()
        ) : (
          <i>You haven&apos;t added any questions yet.</i>
        )}
      </div>
    </>
  );
};

export default QuestionsForCandidate;

const Label = styled.h5`
  margin: 3rem 0 0.5rem;
  font-size: 16px;
  font-weight: 600;
  color: #444444;
`;
